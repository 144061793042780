// apiConstants.js

// const API_BASE_URL = "http://localhost:3002/";
const API_BASE_URL = "https://backend.chlk.football/";

const API_BASE_URL_AUTH = `${API_BASE_URL}auth`;
const API_BASE_URL_PAYMENT = `${API_BASE_URL}payment`;
const API_BASE_URL_USER = `${API_BASE_URL}users`;

const API_ROUTES_AUTH = {
  LOGIN: "/login",
  REGISTER: "/register",
  VERIFY_OTP: "/verify",
  REFRESH_TOKEN: "/token/refresh",
  LOGOUT: "/logout",
  REGISTER_OTP: "/register-otp",
  UPDATE_PROFILE: "/updateProfile",
  FORGOT_PASSWORD: "/forgot-password",
  UPDATE_PASSWORD: "/update-password",
  GET_USER_BY_ID: "/getUser",
  GET_USERS: "/getUsers",
  GET_OTP: "/getOtp",
  CHANGE_PASSWORD: "/changepass",
  RESEND_OTP: "/resendOtp",
};
const API_ROUTES_USER = {};
const API_ROUTES_GENERAL = {
  SEND_FEEDBACK: "send-feedback",
  SAVE_PLAY: "plays",
};
const API_ROUTES_PAYMENT = {
  SUBSCRIBE: "/subscribe",
  CANCEL: "/cancel",
  WEBHOOK: "/webhook",
  SUBSCRIPTIONS: "/subscriptions",
  USERS_SUBSCRIPTIONS: "/users-subscriptions",
  RECEIPTS: "/receipts",
  REVENUE: "/revenue",
  PLANS: "/plans",
  ACTIVE: "/active",
  CANCELLED: "/cancelled",
  GET_SUBSCRIPTIONS: "/get-subscriptions",
  GET_USER_SUBSCRIPTION: "/current-plan",
};
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export {
  API_BASE_URL,
  API_ROUTES_GENERAL,
  API_BASE_URL_AUTH,
  API_ROUTES_AUTH,
  API_ROUTES_PAYMENT,
  API_BASE_URL_PAYMENT,
  API_BASE_URL_USER,
  validateEmail,
};
