
import React, { useState, useRef, useEffect } from 'react';
import { Line, Circle, Group } from 'react-konva';
import PerpendicularEnd from './lineEnds/PerpendicularEnd';
import DottedEnd from './lineEnds/DottedEnd';
import ArrowEnd from './lineEnds/ArrowEnd';
import LineContextMenu from '../menus/LineContextMenu';
import calculateWaveLinePoints from './lineEnds/calculateWaveLinePoints';

const CIRCLE_SIZES = {
    CONTROL: { MIN: 5, MAX: 5 },
    HALO: { MIN: 10, MAX: 10 },
    ANCHOR: { MIN: 5, MAX: 5 },
};

function CustomLine(props) {
    const {
        id,
        line,
        lines,
        color,
        colorButtonPressCount,
        strokeTypeButtonPressCount,
        strokeEndButtonPressCount,
        setStrokeTypeButtonPressCount,
        setStrokeEndButtonPressCount,
        selectedColor,
        selectedLineStroke,
        setSelectedLineEnd,
        selectedLineEnd,
        showContextMenu,
        contextMenuPosition,
        handleDeleteClick,
        handleHideContextMenu,
        onLineDelete,
        onLineChange,
        setLines,
        startDrawing,
        setIsMouseDownOnAnchor,
        selectedLineID,
        setSelectedLineID,
        imageRef,
        stageRef,
        setContextMenuPosition,
        setShowContextMenu,
    } = props;
    const isSelected = selectedLineID === id;



    const [controlPoint, setControlPoint] = useState({
        x: (line.controlPoint.x + line.controlPoint.x) / 2,
        y: (line.controlPoint.y + line.controlPoint.y) / 2,
    });
    const customLineRef = useRef();

    const [controlCircleRadius, setControlCircleRadius] = useState(CIRCLE_SIZES.CONTROL.MAX);
    const [haloCircleRadius, setHaloCircleRadius] = useState(CIRCLE_SIZES.HALO.MAX);
    const [anchorCircleRadius, setAnchorCircleRadius] = useState(CIRCLE_SIZES.ANCHOR.MAX);

    useEffect(() => {
        const image = imageRef?.current;
        const initialImagePosition = { x: image?.x(), y: image?.y() };
        const initialImageSize = { width: image?.width(), height: image?.height() };
        const initialRelativePosition = {
            x: (controlPoint?.x - initialImagePosition?.x) / initialImageSize?.width,
            y: (controlPoint?.y - initialImagePosition?.y) / initialImageSize?.height,
        };

        const initialControlCircleRadius = controlCircleRadius / initialImageSize.width;
        const initialHaloCircleRadius = haloCircleRadius / initialImageSize.width;
        const initialAnchorCircleRadius = anchorCircleRadius / initialImageSize.width;

        const handleResize = () => {
            const newImagePosition = { x: image?.x(), y: image?.y() };
            const newImageSize = { width: image?.width(), height: image?.height() };

            setControlPoint({
                x: initialRelativePosition?.x * newImageSize?.width + newImagePosition?.x,
                y: initialRelativePosition?.y * newImageSize?.height + newImagePosition?.y,
            });
            setControlCircleRadius(Math.min(CIRCLE_SIZES.CONTROL.MAX, Math.max(CIRCLE_SIZES.CONTROL.MIN, initialControlCircleRadius * newImageSize.width)));
            setHaloCircleRadius(Math.min(CIRCLE_SIZES.HALO.MAX, Math.max(CIRCLE_SIZES.HALO.MIN, initialHaloCircleRadius * newImageSize.width)));
            setAnchorCircleRadius(Math.min(CIRCLE_SIZES.ANCHOR.MAX, Math.max(CIRCLE_SIZES.ANCHOR.MIN, initialAnchorCircleRadius * newImageSize.width)));
        };


        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [imageRef, controlPoint, controlCircleRadius, haloCircleRadius, anchorCircleRadius]);


    useEffect(() => {

        if (isSelected) {
            onLineChange(id, {
                color: selectedColor,
                strokeType: selectedLineStroke,
                strokeEnd: selectedLineEnd,
            });
        }
    }, [selectedColor, selectedLineStroke, selectedLineEnd, colorButtonPressCount, strokeTypeButtonPressCount, strokeEndButtonPressCount]);



    const handleLineClick = () => {

        setSelectedLineID(isSelected ? '$' : id);
        setShowContextMenu && setShowContextMenu?.(false);
    };

    const handleRightClick = (e) => {
        setSelectedLineID(id);
        e.evt.preventDefault();


        const stage = e.target.getStage();
        const mousePos = stage.getPointerPosition();
        setContextMenuPosition && setContextMenuPosition?.({ x: mousePos.x - 20, y: mousePos.y - 15 });
        setShowContextMenu && setShowContextMenu?.(true);
    };


    const handleAnchorDragMove = (e) => {
        const newEndPos = e.target.position();


        const updatedLines = lines.map(line =>
            line.id === id ? { ...line, endPos: newEndPos } : line
        );


        const connectedLines = updatedLines.filter(line =>
            line.drawnFromId === id
        );


        const finalLines = updatedLines.map(line =>
            connectedLines.find(connectedLine => connectedLine.id === line.id)
                ? { ...line, startPos: newEndPos }
                : line
        );

        setLines(finalLines);


        const largerCircle = customLineRef.current.findOne('.larger-circle');
        if (largerCircle) {
            largerCircle.position(newEndPos);
        }


        connectedLines.forEach(line => {
            const lineNode = customLineRef.current.findOne(`.line-${line.id}`);
            if (lineNode) {
                lineNode.points([newEndPos?.x, newEndPos?.y, line.controlPoint?.x, line.controlPoint.y, line.endPos?.x, line.endPos?.y]);
            }
        });
    };

    const handleControlPointDragMove = (e) => {
        const newControlPoint = e.target.position();

        setControlPoint(newControlPoint);


        const newPoints = [line.startPos.x, line.startPos.y, newControlPoint.x, newControlPoint.y, line.endPos.x, line.endPos.y];
        const updatedLines = lines.map(l =>
            l.id === id ? { ...l, points: newPoints, controlPoint: newControlPoint } : l
        );
        setLines(updatedLines);
    };

    const dragBoundFunc = (pos) => {
        const imageX = imageRef.current.x();
        const imageY = imageRef.current.y();
        const imageWidth = imageRef.current.width();
        const imageHeight = imageRef.current.height();


        let newX = pos.x;
        let newY = pos.y;


        if (newX < imageX) {
            newX = imageX;
        } else if (newX > imageX + imageWidth) {
            newX = imageX + imageWidth;
        }

        if (newY < imageY) {
            newY = imageY;
        } else if (newY > imageY + imageHeight) {
            newY = imageY + imageHeight;
        }

        return { x: newX, y: newY };
    };

    const direction = {
        x: controlPoint.x - line.startPos.x,
        y: controlPoint.y - line.startPos.y
    };

    const length = Math.sqrt(direction.x * direction.x + direction.y * direction.y);
    const normalizedDirection = {
        x: direction.x / length,
        y: direction.y / length
    };

    const cutOffLengthX = 18;
    const cutOffLengthY = 12;
    const newStartPoint = {
        x: line.startPos.x + normalizedDirection.x * cutOffLengthX,
        y: line.startPos.y + normalizedDirection.y * cutOffLengthY
    };


    let waveLinePoints = calculateWaveLinePoints(line, controlPoint);
    return (
        <>
            <Group
                onContextMenu={handleRightClick}
                onDblTap={handleRightClick}
                ref={customLineRef}
            >
                <Line
                    points={selectedLineStroke === 'squiggle' ? waveLinePoints : [line.startPos.x, line.startPos.y, controlPoint.x, controlPoint.y, line.endPos.x, line.endPos.y]}
                    stroke="transparent"
                    strokeWidth={30}
                    tension={0.3}
                    lineCap="round"
                    onClick={handleLineClick}
                    onTap={handleLineClick}
                />
                <Line
                    points={
                        (isSelected && line.strokeType === 'squiggle')
                            ? waveLinePoints
                            : (line.strokeType === 'squiggle')
                                ? waveLinePoints
                                : (line.attachedShapeId === null || line.attachedShapeId === '$' || line.attachedShapeId === undefined)
                                    ? [line.startPos.x, line.startPos.y, controlPoint.x, controlPoint.y, line.endPos.x, line.endPos.y]
                                    : [newStartPoint.x, newStartPoint.y, controlPoint.x, controlPoint.y, line.endPos.x, line.endPos.y]
                    }
                    stroke={line.color}
                    strokeWidth={
                        isSelected && line.strokeType === 'dotted' ? 4 :
                            line.strokeType === 'dotted' ? 4 :
                                2.5
                    }
                    tension={0.3}
                    lineCap="round"
                    name={`line-${line.id}`}
                    onClick={handleLineClick}
                    onTap={handleLineClick}
                    dash={isSelected && line.strokeType === 'dashed' ? [10, 10] : isSelected && line.strokeType === 'dotted' ? [1.5, 9] : line.strokeType === 'dashed' ? [10, 10] : line.strokeType === 'dotted' ? [1.5, 9] : [0, 0]}
                />
                {/* Arrow Line End */}
                {line.strokeEnd === 'arrow' && (
                    <ArrowEnd line={line} controlPoint={controlPoint} color={line.color} handleLineClick={handleLineClick} />
                )}
                {/* Perpendicular Line End */}
                {line.strokeEnd === 'perpendicular' && (
                    <PerpendicularEnd line={line} controlPoint={controlPoint} color={line.color} handleLineClick={handleLineClick} />
                )}
                {/* Dotted Line End */}
                {line.strokeEnd === 'dotted' && (
                    <DottedEnd line={line} color={line.color} handleLineClick={handleLineClick} />
                )}
                {/* Line end anchor */}
                {isSelected && (
                    <Group>
                        {/* Control point */}
                        <Circle
                            x={controlPoint.x}
                            y={controlPoint.y}
                            radius={controlCircleRadius}
                            fill="darkgrey"
                            stroke="black"
                            strokeWidth={2}
                            draggable
                            onDragMove={handleControlPointDragMove}
                        />
                        {/* Halo */}
                        <Circle
                            x={line.endPos.x}
                            y={line.endPos.y}
                            radius={haloCircleRadius}
                            stroke="black"
                            strokeWidth={0}
                            name="larger-circle"
                            fill="white"
                            shadowBlur={15}
                            shadowColor='#184267'
                            onMouseDown={(e) => {
                                const startPos = e.target.getStage().getPointerPosition();
                                startDrawing(startPos, '$', id, null);
                                setIsMouseDownOnAnchor(true);
                                e.target.moveToTop();
                                e.cancelBubble = true;


                                const smallerCircle = customLineRef.current.findOne('.smaller-circle');
                                if (smallerCircle) {
                                    smallerCircle.moveToTop();
                                }
                            }}
                            onTouchStart={(e) => {
                                const startPos = e.target.getStage().getPointerPosition();
                                startDrawing(startPos, '$', id, null);
                                setIsMouseDownOnAnchor(true);
                                e.target.moveToTop();
                                e.cancelBubble = true;


                                const smallerCircle = customLineRef.current.findOne('.smaller-circle');
                                if (smallerCircle) {
                                    smallerCircle.moveToTop();
                                }
                            }}
                            onMouseEnter={(e) => {
                                const container = e.target.getStage().container();


                                container.style.cursor = 'crosshair';
                            }}
                            onMouseLeave={(e) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = 'default';
                            }}
                        />
                        {/* Anchor */}
                        <Circle
                            x={line.endPos.x}
                            y={line.endPos.y}
                            radius={anchorCircleRadius}
                            fill="darkgrey"
                            stroke={"black"}
                            strokeWidth={0.5}
                            onDragMove={handleAnchorDragMove}
                            draggable
                            dragBoundFunc={dragBoundFunc}
                            name="smaller-circle"
                        />
                    </Group>
                )}
                {/* {showContextMenu &&
                    <LineContextMenu
                        position={contextMenuPosition}
                        onDelete={handleDeleteClick}
                        onMouseLeave={handleHideContextMenu}

                        setSelectedLineEnd={setSelectedLineEnd}
                        selectedLineEnd={selectedLineEnd}
                        setStrokeEndButtonPressCount={setStrokeEndButtonPressCount}
                        handleHideContextMenu={handleHideContextMenu}
                        setStrokeTypeButtonPressCount={setStrokeTypeButtonPressCount}
                    />
                } */}
            </Group>
        </>
    );
}

export default CustomLine;