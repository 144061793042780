import { Box, Button, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { PlusIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';

const ScrollTabBar = ({ data, handleMenuItemClick, handleOnClickAddPlay, handleItemClick, currentLayerData }) => {
    const [tests, setTests] = useState([]);
    const [popOverItems, setPopOverItems] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Box
            className="border-t noscroll  border-[#555555]"
            sx={{ position: 'fixed', bottom: 0, width: '80%', backgroundColor: "red", overflowY: "hidden", height: 42, display: 'flex', alignItems: 'center', overflowX: 'auto', bgcolor: ' #333333' }}>
            <div
                onClick={(event) => {
                    handleOnClickAddPlay();
                }}
                className="flex items-center cursor-pointer justify-center"
                key={"click"}
                style={{ width: "46px", height: "100%", flexWrap: 'wrap' }}
            >
                <div className="w-10 h-10 rounded-full hover:bg-[#E1F2FB] hover:bg-opacity-25 flex items-center justify-center"
                    onClick={handleOnClickAddPlay}
                >
                    <AddIcon
                        style={{ width: "16px", height: "18px", color: "#D9F0F9" }}
                        key={"iconAdd"}
                    />
                </div>
            </div>
            {data?.map((text, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', height: "40px" }}>
                    <div
                        style={{
                            height: "40px",
                            width: "max-content",
                            maxWidth: "fit-content",
                            padding: "6px 6px",
                            border: "1px solid #E1F2FB",
                            cursor: "pointer",
                            backgroundColor: text?.name == currentLayerData?.name ? '#2B76BA' : null
                        }}
                        onClick={(event) => {
                            //console.log("Main click===>", text, index)
                            setPopOverItems({
                                item: text,
                                index
                            })
                            handleItemClick(text)
                           
                        }}
                    >
                        <Tooltip key={index} title={text.name}>
                            <div
                                size="small"

                                style={{
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    display: "flex",
                                    width: "fit-content",
                                    maxWidth: "fit-content",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginInline: "14px",
                                    color: "#D9F0F9",
                                    position: "relative", // Ensure the parent div controls positioning
                                }}
                                onClick={(event) => {
                                    //console.log("Main click===>", text, index)
                                    handleItemClick(text)

                                    setPopOverItems({
                                        item: text,
                                        index
                                    })
                                    // handlePopoverOpen(event)
                                }}
                            >{text?.name}
                                {/* {text?.name?.length <= 6 ? text?.name : text?.name?.length > 6 ? `${text?.name?.slice(0, 6)}...` : ''} */}

                                <div
                                    onClick={(event) => {
                                        //console.log("Main click===>", text, index)
                                        // handleItemClick(text)

                                        setPopOverItems({
                                            item: text,
                                            index
                                        })
                                        handlePopoverOpen(event)
                                    }}
                                >
                                    <ArrowDropDownIcon
                                        style={{
                                            marginTop: "4px",
                                            color: "#D9F0F9",
                                            transform: open ? "rotate(180deg)" : null,
                                            marginLeft: "10px",
                                        }}
                                    />
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        sx={{

                        }}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Box style={{
                            backgroundColor: "#333333",
                            width: "125px",
                            color: "white",
                            paddingTop: "5px",
                            paddingBottom: "5px"
                            // textAlign: "center"
                        }}

                        >
                            <Typography
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        "#2B76BA";
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = "#333333";
                                }}

                                onClick={() => {
                                    handleMenuItemClick(popOverItems?.item, "Delete", popOverItems?.index)
                                    handlePopoverClose()
                                    //console.log("Delete ", popOverItems)
                                }} sx={{ cursor: 'pointer', padding: "6px 16px" }} >Delete</Typography>
                            <Typography
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        "#2B76BA";
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = "";
                                }}
                                onClick={() => {
                                    handleMenuItemClick(popOverItems?.item, "Rename", popOverItems?.index)
                                    handlePopoverClose()
                                    //console.log("Rename ", popOverItems)
                                }} sx={{ paddingLeft: "20px", cursor: 'pointer', padding: "6px 16px" }} > Rename</Typography>
                            <Typography
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        "#2B76BA";
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = "";
                                }}
                                onClick={() => {
                                    handleMenuItemClick(popOverItems?.item, "Duplicate", popOverItems?.index)
                                    handlePopoverClose()
                                    //console.log("Duplicate ", popOverItems)
                                }} sx={{ paddingLeft: "20px", cursor: 'pointer', padding: "6px 16px" }} >Duplicate</Typography>
                            <Typography
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        "#2B76BA";
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = "";
                                }}
                                onClick={() => {
                                    handleMenuItemClick(popOverItems?.item, "Save", popOverItems?.index)
                                    handlePopoverClose()
                                    // //console.log("Save ", popOverItems)
                                }} sx={{ paddingLeft: "20px", cursor: 'pointer', padding: "6px 16px" }} >Save</Typography>
                        </Box>
                    </Popover>
                </Box >
            ))}
        </Box >
    );
};

export default ScrollTabBar;