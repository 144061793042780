import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CancelPage from './pages/CancelPage';
import ComingSoon from './pages/ComingSoon';
import App from './App';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import OtpScreen from './pages/Otp';
import Landingpage from './pages/Landingpage';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import { useDispatch, useSelector } from 'react-redux';
import Plans from './pages/Plans';
import Billing from './pages/Billing';
import Users from './pages/Users';
import FabricExample from './pages/Fabric';
import { jwtDecode } from "jwt-decode";
import { userToken, userUpdate } from './redux/slices/userSlice';
import useAppUsageTracker from './hooks/useAppUsageTracker';

const RouterContainer = () => {
    const isAuthenticated = useSelector(state => state.user.loggedIn);
    const user = useSelector(state => state.user.user);
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch();
    const [redirectLogin, setRedirectLogin] = useState(false);

    // const usageTime = useAppUsageTracker();
    // console.log("Usage Time===>", usageTime);

    useEffect(() => {
        if (token) {
            let decodedToken = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken?.exp < currentTime) {
                dispatch(userUpdate({ user: {}, loggedIn: false }));
                setRedirectLogin(true);
                dispatch(userToken({ token: '' }));

                // Clear localStorage or sessionStorage here if needed
                sessionStorage.clear();
                localStorage.removeItem('persist:root'); // Example of clearing token in localStorage
            } else {
                setRedirectLogin(false);
            }
        } else {
            dispatch(userUpdate({ user: {}, loggedIn: false }));
            setRedirectLogin(true);
        }
    }, [token, dispatch]);

    return (
        <Router key={isAuthenticated ? 'authenticated' : 'unauthenticated'}>
            <Routes>
                <Route
                    path="/diagram"
                    element={
                        isAuthenticated && user.role === 'user' && !redirectLogin ? (
                            user.planName === null ? <Navigate to="/plans" /> : <App />
                        ) : user.role === 'admin' ? (
                            <Navigate to="/admin/billing" />
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/fabric" element={<FabricExample />} />
                <Route path="/cancel" element={<CancelPage />} />

                <Route
                    path="/"
                    element={
                        isAuthenticated ? (
                            user.role === 'admin' ? (
                                <Navigate to="/admin/billing" />
                            ) : (
                                <Navigate to={user.planName === null ? "/plans" : "/diagram"} />
                            )
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route
                    path="/admin/billing"
                    element={
                        isAuthenticated && user.role === 'admin' ? (
                            <Users />
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                {/* Public Routes */}
                <Route path="/login" element={isAuthenticated ? <Navigate to="/diagram" /> : <Login />} />
                <Route path="/register" element={isAuthenticated ? <Navigate to="/diagram" /> : <SignUp />} />
                <Route path="/plans" element={!isAuthenticated ? <Navigate to="/login" /> : <Plans />} />
                <Route path="/billing" element={!isAuthenticated ? <Navigate to="/login" /> : isAuthenticated && user.planName === null ? <Navigate to="/plans" /> : <Billing />} />
                <Route path="/forgot-password" element={isAuthenticated ? <Navigate to="/diagram" /> : <ForgotPassword />} />
                <Route path="/update-password" element={isAuthenticated ? <Navigate to="/diagram" /> : <ChangePassword />} />
                <Route path="/landingpage" element={isAuthenticated ? <Navigate to="/diagram" /> : <Landingpage />} />
                <Route path="/verification" element={isAuthenticated ? <Navigate to="/diagram" /> : <OtpScreen />} />

                {/* Catch-all route */}
                <Route path="*" element={isAuthenticated ? <Navigate to="/diagram" /> : <Login />} />
            </Routes>
        </Router>
    );
};

export default RouterContainer;
