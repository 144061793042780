import React, { useCallback, useEffect, useState } from "react";
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import light from "../components/assets/public/Images/light.svg";
import moon from "../components/assets/public/Images/moon.svg";
import Profile from "../components/assets/public/profile.png";
import Logo from "../components/assets/public/static/assets/chlk-logo-white.png";
import {
  Input,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import { Box, Button, Popper } from "@mui/material";
import {
  EditOutlined,
  LogoutOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";

import { signOut } from "aws-amplify/auth";
import { useDispatch, useSelector } from "react-redux";
import { userToken, userUpdate } from "../redux/slices/userSlice";
import { DashboardOutlined } from "@mui/icons-material";
import redo from "../../src/components/assets/public/Images/redo.svg";
import undo from "../../src/components/assets/public/Images/undo.svg";
import button from "../../src/components/assets/public/Images/button.svg";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import CloseIcon from "@mui/icons-material/Close";
import { BsTrash3Fill } from "react-icons/bs";
import { PiFileJpg, PiFilePng } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

// ==============================|| HEADER CONTENT - PROFILE ||============================== //
const Header = ({ Navigate, isOpen, title = '', Redo, Undo, disableCursor, NameUpdate, disableView = true, handleDeleteAll, handleDownloadPNG, handleDownloadJPG, clearFormation }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorShare, setAnchorShare] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipTimeoutId, setTooltipTimeoutId] = useState(null);
  const [editingTitle, setEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user) ?? {}

  const analytics = getAnalytics();
  // //console.log("|User in header", user)
  const handleClick = useCallback((event) => {
    if (!user?.loggedIn) {
      sessionStorage.clear();
      localStorage.removeItem('persist:root');
      Navigate?.("/login");
    }
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
    if (!anchorEl) {
      setAnchorShare(null);

    }
    isOpen?.(Boolean(anchorEl ? null : event.currentTarget));
  }, [anchorEl, isOpen, user?.loggedIn]);
  const handleClickShare = useCallback((event) => {
    if (!user?.loggedIn) {
      sessionStorage.clear();
      localStorage.removeItem('persist:root');
      Navigate?.("/login");
    }
    setAnchorShare((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
    if (!anchorShare) {
      setAnchorEl(null);

    }
    isOpen?.(Boolean(anchorShare ? null : event.currentTarget));
  }, [anchorShare, isOpen, user?.loggedIn]);

  useEffect(() => {
    setNewTitle(title)
  }, [title])



  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const openShare = Boolean(anchorShare);
  const idShare = openShare ? "simple-popper" : undefined;
  const [openMode] = useState(false);
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);
  const token = useSelector(state => state.user.token)


  useEffect(() => {
    // //console.log('User ', user)
    // //console.log('User token', token)
    if (token) {
      let decodedToken = jwtDecode(token);
      // //console.log("Decoded Token", decodedToken);
      const currentTime = Math.floor(Date.now() / 1000);
      // //console.log(currentTime)

      if (decodedToken?.exp < currentTime) {
        // //console.log("Token expired.");

        dispatch(userUpdate({ user: {}, loggedIn: false }));
        dispatch(userToken({ token: '' }));
      }
    } else {
      dispatch(userUpdate({ user: {}, loggedIn: false }));
      Navigate?.("/login");
    }
  }, [token])



  useEffect(() => {
    // //console.log('User ', user)
    // //console.log('User token', token)
    if (!user?.loggedIn) {
      sessionStorage.clear();
      localStorage.removeItem('persist:root');
      Navigate?.("/login");
    }
  }, [user?.loggedIn])





  const handleLogout = async () => {
    // await signOut({ global: true }).then((resp) => {
    dispatch(userUpdate({ user: {}, loggedIn: false }));

    Navigate?.("/login");
    // });
  };
  const handleDashboard = async () => {
    if (!user?.loggedIn) {
      sessionStorage.clear();
      localStorage.removeItem('persist:root');
      Navigate?.("/login");
    } else {
      logEvent(analytics, 'Diagram');
      Navigate?.("/diagram");
    }
  };
  const handlePlans = async () => {
    if (!user?.loggedIn) {
      sessionStorage.clear();
      localStorage.removeItem('persist:root');
      Navigate?.("/login");
    } else {
      logEvent(analytics, 'Plans');

      Navigate?.("/plans");
    }
  };
  const handleBilling = async () => {
    if (!user?.loggedIn) {
      sessionStorage.clear();
      localStorage.removeItem('persist:root');
      Navigate?.("/login");
    } else {
      logEvent(analytics, 'Plans');

      Navigate?.("/billing");
    }
  };

  const mode = "LIGHT";
  // const { mode, onChangeMode } = useConfig();
  // const handleModeToggle = () => {
  //     const newMode = mode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;
  //     onChangeMode(newMode);
  // };
  const handleToggleSpeedDial = () => {
    setIsSpeedDialOpen((prevIsSpeedDialOpen) => !prevIsSpeedDialOpen);
    if (!isSpeedDialOpen) {
      setAnchorEl(null);
    }
  };
  const handleCloseSpeedDial = () => {
    setIsSpeedDialOpen(false);
  };
  const handleMouseEnter = (index) => {
    const timeoutId = setTimeout(() => {
      setTooltipOpen((prevState) => ({ ...prevState, [index]: true }));
    }, 400); // delay time
    setTooltipTimeoutId(timeoutId);
  };

  const handleMouseLeave = (index) => {
    clearTimeout(tooltipTimeoutId);
    const timeoutId = setTimeout(() => {
      setTooltipOpen((prevState) => ({ ...prevState, [index]: false }));
    }, 300); // delay time
    setTooltipTimeoutId(timeoutId);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (anchorEl && !anchorEl?.contains(event?.target)) {
        setAnchorEl(null);
        setAnchorShare(null)
        isOpen?.(Boolean(anchorEl || anchorShare ? null : event.currentTarget));
      }
      if (anchorShare && !anchorShare?.contains(event?.target)) {
        setAnchorEl(null);
        setAnchorShare(null)
        isOpen?.(Boolean(anchorEl || anchorShare ? null : event.currentTarget));
      }
    };
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [anchorEl, anchorShare]);

  const handleCloseAll = () => {
    if (!anchorEl) {
      //console.log("not open")
      setIsSpeedDialOpen(false);
    }

  }
  const actions = [

    {
      name: "Delete All",
      icon: <BsTrash3Fill size={20} color={'white'} />,
      action: handleDeleteAll,
    },
    {
      name: "Download PNG",
      icon: <PiFilePng size={25} color={'white'} />,
      action: handleDownloadPNG,
    },
    {
      name: "Download JPG",
      icon: <PiFileJpg size={25} color={'white'} />,
      action: handleDownloadJPG,
    },
  ];
  useEffect(() => {
    const handleClickOutside = (event) => {
      const speedDial = document.getElementById('speed-dial');
      if (speedDial && !speedDial.contains(event.target)) {
        setIsSpeedDialOpen(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleLogoClick = () => {
    if (!disableCursor) {
      logEvent(analytics, 'Diagram');
      navigate('/diagram');
    }
  };
  const handleTitleDoubleClick = () => {
    if (!disableCursor) {

      setEditingTitle(true);
    }
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);

  };

  const handleTitleBlur = () => {
    setEditingTitle(false);
    // Here you can perform any action you want when the title is changed
    //console.log("New title:", newTitle);
    if (!user?.loggedIn) {
      sessionStorage.clear();
      localStorage.removeItem('persist:root');
      Navigate?.("/login");
    } else
      NameUpdate(newTitle)

  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTitleBlur();
    }
  };
  return (
    <div className="border-b border-[#555555]  w-full flex bg-[#333333] items-center h-[10vh] pl-6 justify-between">
      <div className={`sm:flex-none w-[40%] cursor-pointer`} onClick={handleLogoClick}>
        <img className="w-[58px] h-11 " src={Logo} alt="logo" />
      </div>
      {/* {title && <div className="w-[20%] flex items-center justify-center">
        <p className="text-[20px] font-bold text-[#F1FAFF]">
          {title}
        </p>
      </div>} */}
      <div className={`flex w-[60%] align-center  justify-between `}>
        <div className={`flex-auto w-[50%]  cursor-pointer self-center`}>
          {editingTitle ? (
            <div className="sm:flex-initial w-[100%] self-center lg:flex flex items-center justify-centerr">
              <Input
                type="text"
                style={{ color: "white" }}
                value={newTitle}
                onChange={handleTitleChange}
                onBlur={handleTitleBlur}
                onKeyDown={handleKeyPress}
                autoFocus
              />
            </div>
          ) : (
            <div className="sm:flex-initial w-[100%] self-center lg:flex flex items-center justify-center" onDoubleClick={handleTitleDoubleClick}>
              <p className="text-[20px] w-full font-bold text-[#F1FAFF]" >
                {newTitle?.length <= 10 ? newTitle : newTitle?.length > 10 ? `${newTitle?.slice(0, 10)}...` : ''}

              </p>
            </div>
          )}
        </div>
        <div className=" flex-auto w-[50%]   justify-end items-center">
          <div className="flex-initial flex  w-[100%] justify-evenly items-center">
            {disableView ? (<div
              className="flex  justify-between  items-center" ></div>) : (<div className="flex  justify-between  items-center">
                <Button
                  color="white"
                  value="OffenseExtra"
                  sx={{
                    // marginBottom: '-12px',
                    background: '#333',
                    borderColor: '#333',
                    marginRight: "10px",
                    padding: '0px 5px',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '0.7rem',
                    transition: 'text-shadow 0.3s',
                    ':hover': {
                      textShadow: '0 0 10px rgba(255, 255, 255, 0.8)',
                    },
                    textDecoration: 'underline',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                  onClick={clearFormation}
                >
                  Clear All
                </Button>
                <img
                  className=" opacity-50 hover:opacity-100 cursor-pointer  "
                  src={undo}
                  alt="undo"
                  onClick={Undo}
                />
                <img
                  className=" opacity-50 ml-5 cursor-pointer hover:opacity-100"
                  src={redo}
                  alt="redo"
                  onClick={Redo}
                />
              </div>)}
            {disableView ? (<div
              className={`flex items-center cursor-pointer ${disableCursor ? 'cursor-not-allowed' : 'cursor-pointer'}  justify-center`}
            ></div>) : (
              <div
                onClick={handleClickShare}
                className={`flex items-center cursor-pointer ${disableCursor ? 'cursor-not-allowed' : 'cursor-pointer'}  justify-center`}
              >
                <div className="flex  items-center justify-center">
                  <Button type="submit" label="Submit" style={{ backgroundColor: "#2B76BA", borderRadius: "4px", color: "white", fontWeight: "medium", padding: "5px 15px" }}>
                    Share
                  </Button>
                </div>

              </div>)}
            <Popper
              id={idShare}
              open={openShare}
              className="rounded-md"
              anchorEl={anchorShare}
              placement="bottom-start"
            >
              <Box
                sx={{
                  width: 200,
                  borderRadius: "4px",
                  zIndex: 999,
                  mt: "22px",
                  bgcolor: "#333333",
                  color: "white",
                  // pl: 1,
                  // pt: "2px",
                  // pb: "2px",
                }}
                className="z-30"
              >
                <MenuList autoFocusItem={openShare} id="menu-list-grow" sx={{ paddingY: '-1px' }}>
                  {actions.map((item, index) => (
                    <MenuItem sx={{
                      // marginY: "5px",
                      "&:hover": {
                        backgroundColor: "#2B76BA",
                        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                          color: "white"
                        }
                      }
                    }} className="main" key={`action-${index}`} onClick={item.action}>
                      <ListItemIcon className="hover" >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.name} style={{ color: "#E4E4E4" }} />
                    </MenuItem>
                  ))}
                </MenuList>
              </Box>
            </Popper>
            <div
              onClick={handleClick}
              className={`flex items-center cursor-pointer ${disableCursor ? 'cursor-not-allowed' : 'cursor-pointer'}  justify-center`}
            >
              <div className="w-[45px]  h-[45px] bg-[#E1F2FB] flex items-center justify-center rounded-full">
                <p className="font-bold text-[20px] text-[#2B76BA]">{user?.user?.firstName[0] ?? ''}{user?.user?.lastName[0] ?? ''}</p>
              </div>
              <p className="text-white font-medium ml-4 text-[14px] sm:hidden lg:block">
                {user?.user?.firstName}
              </p>
            </div>
            <Popper
              id={id}
              open={open}
              className="rounded-md"
              anchorEl={anchorEl}
              placement="bottom-start"
            >
              <Box
                sx={{
                  width: 200,
                  borderRadius: "4px",
                  zIndex: 999,
                  mt: "16px",
                  bgcolor: "#333333",
                  color: "white",
                  // pl: 1,
                  pt: "5px",
                  pb: "5px",
                }}
                className="z-30"
              >
                {user?.user?.role != 'admin' && !disableCursor && <MenuItem className="main" onClick={handleDashboard} sx={{
                  "&:hover": {
                    backgroundColor: "#2B76BA",
                    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                      color: "white"
                    }
                  }
                }}>
                  <ListItemIcon >
                    <DashboardOutlined className="hover" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Diagram"
                    style={{ color: "#E4E4E4" }}
                  />
                </MenuItem>}
                {user?.user?.role != 'admin' && !disableCursor && <MenuItem className="main" onClick={handlePlans} sx={{
                  "&:hover": {
                    backgroundColor: "#2B76BA",
                    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                      color: "white"
                    }
                  }
                }}>
                  <ListItemIcon>
                    <CalendarOutlined className="hover" />
                  </ListItemIcon>
                  <ListItemText primary="Plans" style={{ color: "#E4E4E4" }} />
                </MenuItem>}
                {user?.user?.role != 'admin' && !disableCursor && <MenuItem className="main" onClick={handleBilling} sx={{
                  "&:hover": {
                    backgroundColor: "#2B76BA",
                    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                      color: "white"
                    }
                  }
                }}>
                  <ListItemIcon>
                    <WalletOutlined className="hover" />
                  </ListItemIcon>
                  <ListItemText primary="Billing" style={{ color: "#E4E4E4" }} />
                </MenuItem>}
                <MenuItem className="main" onClick={handleLogout} sx={{
                  "&:hover": {
                    backgroundColor: "#2B76BA",
                    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                      color: "white"
                    }
                  }
                }}>
                  <ListItemIcon>
                    <LogoutOutlined className="hover" />
                  </ListItemIcon >
                  <ListItemText primary="Logout" style={{ color: "#E4E4E4" }} />
                </MenuItem>
              </Box>
            </Popper>
          </div>
        </div>

      </div>
    </div>
  );
};
export default Header;
