import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useTable, usePagination, useSortBy } from 'react-table'; // Import useSortBy hook
import SearchBar from './SearchBar';
import SwapVertIcon from '@mui/icons-material/SwapVert';
const TableComponent = ({ tableData, loading, title = "Billing + Plans", columns, handleCellClick }) => {
  const [data, setData] = useState(tableData);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setData(tableData);
    setFilteredData(tableData);
  }, [tableData]);

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   page,
  //   prepareRow,
  //   nextPage,
  //   previousPage,
  //   canNextPage,
  //   canPreviousPage,
  //   pageOptions,
  //   state: { pageIndex },
  // } = useTable(
  //   {
  //     columns,
  //     data: filteredData,
  //     initialState: { pageIndex: 0 },
  //     disableSortBy: true,
  //   },
  //   useSortBy,
  //   usePagination,
  // );

  // const handleSearch = (searchTerm) => {
  //   const filtered = tableData.filter((row) =>
  //     Object.values(row).some(
  //       (cellValue) =>
  //         cellValue &&
  //         cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   );
  //   setFilteredData(filtered);
  // };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const handleSearch = (searchTerm) => {
    const filtered = tableData.filter((row) =>
      Object.values(row).some(
        (cellValue) =>
          cellValue &&
          cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  // Sorting function that only sorts the data within the current page
  const sortPageData = (rows, sortBy) => {
    const sortedRows = [...rows];
    if (sortBy.length === 0) return sortedRows;

    const sortColumn = columns.find(col => col.accessor === sortBy[0].id);
    if (!sortColumn) return sortedRows;

    sortedRows.sort((a, b) => {
      const aValue = a[sortColumn.accessor];
      const bValue = b[sortColumn.accessor];

      if (aValue === undefined || bValue === undefined) return 0; // Add null check

      // Implement custom sorting logic here
      // Example: sorting alphanumeric strings
      return aValue.localeCompare(bValue);
    });

    // Apply descending order if needed
    if (sortBy[0].desc) {
      sortedRows.reverse();
    }

    return sortedRows;
  };
  //console.log(page, sortBy)

  const sortedPageData = sortPageData(page, sortBy);



  return (
    <div className='h-[90vh] flex flex-col items-center justify-center rounded-[8px] pt-10 text-white'>
      <div className='w-[70%] h-full'>
        <div className='w-full flex items-center justify-center'>
          <div className='w-[100%] mb-6 flex justify-start'>
            <Typography sx={{ fontSize: "44px", fontFamily: "noto serif" }}>{title}</Typography>
          </div>
          <SearchBar data={data} onSearch={handleSearch} backgroundColor={"transparent"} />
        </div>
        <div className='h-[60vh] overflow-auto noscroll'>
          <table {...getTableProps()} className=''>
            <thead className='table-head'>
              {headerGroups.map(headerGroup => (
                <tr style={{ maxWidth: '25%', }} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map(column => {
                    return (
                      <th
                        style={{ backgroundColor: '#1E1E1E', overflow: 'hidden' }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        <span>
                          {column?.id == 'pass5' ? '' : <SwapVertIcon />}
                          {/* {column.isSorted ? (column.isSortedDesc ? <' 🔽' : ' 🔼'>) : column?.id == 'pass5' ? '' : ' 🔽'} */}
                        </span>
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className='radius table-body'>
              {loading ? (
                <tr className='table-row'>
                  <td colSpan={columns?.length} style={{ textAlign: 'center', padding: '10px', backgroundColor: '#1E1E1E' }}>
                    <CircularProgress color="primary" />
                  </td>
                </tr>
              ) : (
                filteredData.length === 0 && tableData.length > 0 ? (
                  <tr>
                    <td colSpan={columns?.length} style={{ textAlign: 'center', padding: '10px', backgroundColor: '#1E1E1E' }}>No results found</td>
                  </tr>
                ) : (
                  sortedPageData?.map(row => {
                    prepareRow(row);
                    return (
                      <tr style={{ maxWidth: '25%', }} className='table-row' {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td   {...cell.getCellProps()} onClick={() => {
                            if (cell?.column?.id === 'pass5') {
                              handleCellClick(cell?.row?.original);
                            }
                          }}>
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )
              )}
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "end" }}>
          <div className='w-[36%] flex items-center justify-between'>
            <div className='mt-5 w-[30%]'>
              Page {pageIndex + 1} of {pageOptions.length}
            </div>
            <Button
              style={{ backgroundColor: "#2B76BA", marginTop: "20px" }}
              className='mt-10 w-[30%]'
              size="small"
              type="submit"
              variant="contained"
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              <Box color="white" className="font-inter font-semibold tracking-widest" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className='tracking-[4px]'>Previous</p>
              </Box>
            </Button>
            <Button
              style={{ backgroundColor: "#2B76BA", marginTop: "20px" }}
              className='mt-10 ml-5 w-[30%]'
              size="small"
              type="submit"
              variant="contained"
              color="primary"
              onClick={nextPage}
              disabled={!canNextPage}
            >
              <Box color="white" className="font-inter font-semibold tracking-widest" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className='tracking-[4px]'>Next</p>
              </Box>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
