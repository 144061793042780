import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
} from "@mui/material";
import "../../App.css";
import { Loader } from "../Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AnimateButton from "../AnimateButton";
import { API_BASE_URL_AUTH, API_ROUTES_AUTH } from '../../constants';
import { toast } from 'react-toastify';
export default function CustomModal(props) {

    const { open, handleModalClose, userValue } = props;
    const [cPassword, setCpassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState({ newPassword: "", cPassword: "" });
    const [lightMode, setLightMode] = useState(true);

    const textStyle = { color: lightMode ? "black" : "white" };
    const border = { color: lightMode ? "black" : "white" };
    const inputBorderColor = "black"

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const regularExpression =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

    useEffect(() => {
        setCpassword("")
        setNewPassword("")
    }, [open])

    const validatePasswords = () => {
        let valid = true;
        let errors = { newPassword: "", cPassword: "" };
        if (!regularExpression.test(newPassword)) {
            errors.newPassword =
                "Password must be 8-16 characters long and include at least one number and one special character.";
            valid = false;
        }
        if (newPassword !== cPassword) {
            errors.cPassword = "Passwords do not match.";
            valid = false;
        }
        setErrors(errors);
        return valid;
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    const handleResetPassword = async () => {
        if (validatePasswords()) {
            setLoader(true);
            const requestBody = {
                password: newPassword,
                email: userValue?.col2
            };
            const requestHeaders = {
                "Content-Type": "application/json",
            };

            await fetch(
                API_BASE_URL_AUTH + API_ROUTES_AUTH.UPDATE_PASSWORD,
                {
                    method: "POST",
                    headers: requestHeaders,
                    body: JSON.stringify(requestBody),
                }
            )
                .then((response) => response.json())
                .then((response) => {
                    setLoader(false);
                    //console.log("Response===>", response);
                    if (response) {
                        toast.success("User Password Updated Successfully!");
                        handleModalClose?.()
                    }
                })
                .catch((error) => {
                    //console.log(error.message);
                    toast.error(error.message);
                    setLoader(false);
                });
        }
    };

    return (
        <Dialog open={open} onClose={handleModalClose}>
            <DialogTitle>Password update</DialogTitle>
            <DialogContent>
                <div className="mt-5">
                    <Stack spacing={1}>
                        <InputLabel htmlFor="new-pass" style={border}>
                            New password
                        </InputLabel>
                        <OutlinedInput
                            id="new-pass"
                            type={showPassword ? "text" : "password"}
                            name="newPassword"
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: border.color,
                                },
                                "& .MuiInputBase-input": {
                                    color: textStyle.color,
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    color: textStyle.color,
                                    opacity: 0.7, 
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: lightMode ? inputBorderColor : "white",
                                },
                            }}
                            size="small"
                            placeholder="Enter new password"
                            fullWidth
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            error={!!errors.newPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleTogglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff style={{ color: '#C8C8C8' }} /> : <Visibility style={{ color: '#C8C8C8' }} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {errors.newPassword && (
                            <Typography color="error">{errors.newPassword}</Typography>
                        )}
                    </Stack>
                </div>
                <div className="mt-5">
                    <Stack spacing={1}>
                        <InputLabel htmlFor="cnfrm-pass" style={border}>
                            Confirm password
                        </InputLabel>
                        <OutlinedInput
                            id="cnfrm-pass"
                            type={showConfirmPassword ? "text" : "password"}
                            name="cnfrmPassword"
                            size="small"
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: border.color,
                                },
                                "& .MuiInputBase-input": {
                                    color: textStyle.color,
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    color: textStyle.color,
                                    opacity: 0.7, 
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: lightMode ? inputBorderColor : "white",
                                },
                            }}
                            placeholder="Enter confirm password"
                            fullWidth
                            onChange={(e) => setCpassword(e.target.value)}
                            value={cPassword}
                            error={!!errors.cPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleToggleConfirmPasswordVisibility}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? (
                                            <VisibilityOff style={{ color: '#C8C8C8' }} />
                                        ) : (
                                            <Visibility style={{ color: '#C8C8C8' }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {errors.cPassword && (
                            <Typography color="error">{errors.cPassword}</Typography>
                        )}
                    </Stack>
                </div>
                <div className='mt-5'>
                    <Grid item xs={12}>
                        <AnimateButton>
                            <Button fullWidth style={{ backgroundColor: "#2B76BA" }} className='mt-5' size="large" type="submit" variant="contained" color="primary" onClick={handleResetPassword} disabled={loader}
                            >
                                <Box color="white" className="font-inter font-semibold tracking-widest" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {loader ? <Loader /> : <p className=' tracking-[4px] w-[70px]'>Submit</p>}
                                </Box>
                            </Button>
                        </AnimateButton>
                    </Grid>
                </div>
            </DialogContent>
        </Dialog>
    );
}
