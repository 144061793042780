import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
// import logo from "../assets/logo.svg";
// import privacy from "../assets/Vector (19).png";
import { useNavigate } from "react-router-dom";
import '../App.css'
import Wrapper from '../components/Wrapper';
import { resetPassword } from 'aws-amplify/auth';
import { Loader } from '../components/Loader';
import { API_BASE_URL_AUTH, API_ROUTES_AUTH, validateEmail } from '../constants';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { signUpdata } from '../redux/slices/userSlice';
import AnimateButton from '../components/AnimateButton';
const ForgotPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [loader, setLoader] = useState(false)
    const [lightMode, setLightMode] = useState(false);
    const [emailError, setEmailError] = useState('');
    const dispatch = useDispatch()

    const handleResetPassword = async () => {
        setLoader(true)
        if (!validateEmail(email)) {
            setEmailError('Invalid email address.');
        }
        else {
            const requestBody = {
                email
            };
            const requestHeaders = {
                "Content-Type": "application/json",
            };

            await fetch(API_BASE_URL_AUTH + API_ROUTES_AUTH.FORGOT_PASSWORD, {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(requestBody)
            }).then(response => response.json())
                .then(response => {
                    setLoader(false)
                    //console.log("Response===>", response)
                    if (response.message == 'OTP sent on the Email Successfully') {
                        toast.success(response?.message)
                        dispatch(signUpdata({ email }))
                        navigate('/verification')
                    } else {
                        toast.error(response.message)
                    }

                }).catch((error) => {
                    //console.log(error.message);
                    toast.error(error.message)
                    setLoader(false)
                })
        }
        // if (email) {
        //     resetPassword({ username: email })
        //     setLoader(false)
        //     navigate('/update-password', { state: { email } });
        // }

        setLoader(false)
    }
    const textStyle = { color: lightMode ? 'black' : 'white' };
    const border = { color: lightMode ? 'black' : 'white' };
    return (
        <Wrapper>
            <div className={`lg:w-[40%] flex items-center flex-col w-full px-5 sm:px-20 ${lightMode ? "bg-white" : "bg-[#1E1E1E]"}  justify-center`}>
                <div className='w-full'>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <p className='text-[24px] font-bold' style={border}>Forgot Password</p>
                        {/* <Link href="/register" style={{ color: '#1677FF' }}> */}
                        <p className='hover:underline' onClick={() => navigate('/login')} style={{ cursor: 'pointer', color: '#2B76BA' }}>Back to Login</p>
                        {/* </Link> */}
                    </Stack>
                    <div className='mt-5'>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="email-fp" style={border}>Email Address</InputLabel>
                            <OutlinedInput
                                id="email-fp"
                                type="email"
                                name="email"
                                size='small'
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: border.color
                                    },
                                    '& .MuiInputBase-input': {
                                        color: textStyle.color
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: textStyle.color,
                                        opacity: 0.7 // Adjust the opacity for better visibility
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: lightMode ? inputBorderColor : 'white'
                                    }
                                }}
                                placeholder="Enter email address"
                                fullWidth
                                error={!!emailError}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                onKeyDown={(e) => {
                                    if (e.key == 'Enter')
                                        handleResetPassword()
                                }}
                            />
                        </Stack>
                        {emailError && <Typography color="error">{emailError}</Typography>}
                    </div>
                    <div className='text-start mt-5 text-sm ' style={border}>
                        Do not forget to check the SPAM box.
                    </div>

                    <div className='mt-5'>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button fullWidth style={{ backgroundColor: "#2B76BA" }} className='mt-5' size="large" type="submit" variant="contained" color="primary" onClick={handleResetPassword} disabled={loader}
                                >
                                    <Box color="white" className="font-inter font-semibold tracking-widest" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {loader ? <Loader /> : <p className=' tracking-[4px] '>Reset Password</p>}
                                    </Box>
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </div>
                    {/* <div className='mt-5'>
                        <Grid item xs={12} >
                            <Button fullWidth className='mt-5' size="large" type="submit" variant="contained" color="primary" onClick={handleResetPassword}>
                                <Box color="white" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {loader ? <Loader /> : <p> Reset Password</p>}
                                    {/* <CircularProgress size={24} color="inherit" /> */}
                    {/* </Box>
                            </Button>
                        </Grid>
                    </div> */}
                </div>
            </div>
        </Wrapper>
    )
};
export default ForgotPassword;