import React from "react";
import ReactDOM from "react-dom/client";
import RouterContainer from "./RouterContainer";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import config from "./amplifyconfiguration.json";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { initializeApp } from "firebase/app";
import { logEvent, getAnalytics } from "firebase/analytics";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
Amplify.configure(config);

const firebaseConfig = {
  apiKey: "AIzaSyBjTfbs4JHXyXdkhbTGJvF6WELb_iGfbOE",
  authDomain: "chlk-app.firebaseapp.com",
  projectId: "chlk-app",
  storageBucket: "chlk-app.appspot.com",
  messagingSenderId: "777300031192",
  appId: "1:777300031192:web:af80abd75c6a2c2a3b09ad",
  measurementId: "G-EBNY0KPGP6",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, "Main");
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <RouterContainer />
        <ToastContainer closeButton position="bottom-right" />
      </React.StrictMode>
    </PersistGate>
  </Provider>
);
