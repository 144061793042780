
import React, { useState, useRef, useEffect } from 'react';
import StageDimensionsContext from './contexts/StageDimensionsContext';
import Canvas from './components/Canvas';
import Stencil from './components/Stencil';
import useShapes from './hooks/useShapes';
import useTextTags from './hooks/useTextTags';
import useBackground from './hooks/useBackground';
import { ThemeProvider } from '@mui/material/styles';
import theme from './config/theme';
import CloseIcon from '@mui/icons-material/Close';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BsTrash3Fill } from "react-icons/bs";
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import { PiFileJpg } from "react-icons/pi";
import { PiFilePng } from "react-icons/pi";
import FontDownloadOffIcon from '@mui/icons-material/FontDownloadOff';
import { AiOutlineDeleteColumn } from "react-icons/ai";
import { LuLogOut } from "react-icons/lu";
import { IoIosAdd } from "react-icons/io";
import './App.css';
import useLines from './hooks/useLines';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { getAnalytics, logEvent } from "firebase/analytics";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import Grid from '@mui/material/Grid';
import { Button, ButtonGroup, backdropClasses } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Header from './components/Header';
import { throttle } from 'lodash';
import { API_BASE_URL, API_BASE_URL_PAYMENT, API_ROUTES_GENERAL, API_ROUTES_PAYMENT } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import FeedBack from "./components/assets/public/Images/feedback.svg";
import { userToken, userUpdate } from './redux/slices/userSlice';
import ScrollTabBar from './ScrollTabBar';
import { jwtDecode } from "jwt-decode";

const options = ['Delete', 'Rename', 'Duplicate', 'Save'];




function App({ signOut, setCurrentUser, showAuthenticator, setShowAuthenticator }) {
  const imageRef = useRef(null);
  const stageRef = useRef(null);
  const navigate = useNavigate()
  const [colorButtonPressCount, setColorButtonPressCount] = useState(0);
  const [strokeTypeButtonPressCount, setStrokeTypeButtonPressCount] = useState(0);
  const [strokeEndButtonPressCount, setStrokeEndButtonPressCount] = useState(0);
  const [selectedShapes, setSelectedShapes] = useState([]);
  const [selectedTextTags, setSelectedTextTags] = useState([]);
  const [selectedLineID, setSelectedLineID] = useState('$');
  const [selectedColor, setSelectedColor] = useState(theme.palette.white.main);
  const [selectedTextColor, setSelectedTextColor] = useState(theme.palette.black);
  const [selectedLineColor, setSelectedLineColor] = useState(theme.palette.black);
  const [selectedLineStroke, setSelectedLineStroke] = useState('straight');
  const [selectedLineEnd, setSelectedLineEnd] = useState('straight');
  const [stageDimensions, setStageDimensions] = useState({ width: 0, height: 0 });
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);
  const [currentLayerData, setCurrentLayerData] = useState(null);
  const { backgroundImage, fieldType, setFieldType, setZone, zone, setRedLine, redLine, waterMark, setWatermark } = useBackground();
  const { lines, startPos, endPos, startDrawing, draw, stopDrawing, deleteAllLines, setLines, deleteLine, updateLine } = useLines(imageRef, setSelectedLineID, selectedLineID);
  const { shapes, setShapes, addFormation, addShape, updateShape, deleteShape, deleteFormation, deleteAllShapes, hideShapeContextMenu, flipAllShapes } = useShapes(imageRef, lines, setLines);
  const { textTags, setTextTags, addTextTag, updateTextTag, deleteTextTag, deleteAllTextTags, hideTextTagContextMenu, flipAllTextTags } = useTextTags(imageRef);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipTimeoutId, setTooltipTimeoutId] = useState(null);
  const [items, setItems] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [dialogAction, setDialogAction] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedColorButton, setSelectedColorButton] = useState(0);
  const [popperOpen, setPoperOpen] = useState(false);
  const [lightMode, setLightMode] = useState(false);
  const textStyle = { color: lightMode ? 'black' : 'white' };
  const border = { color: lightMode ? 'black' : '#C8C8C8', };
  const bg = { backgroundColor: lightMode ? '#333333' : '#333333' };
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [redoState, setRedoState] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const [feedBack, setFeedback] = useState(false);
  const [oldDimensions, setOldDimension] = useState({ width: 1, height: 1 });
  const user = useSelector(state => state.user);

  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const analytics = getAnalytics();



  useEffect(() => {
    // //console.log('User ', user)
    // //console.log('User token', token)
    if (token) {
      let decodedToken = jwtDecode(token);
      // //console.log("Decoded Token", decodedToken);
      const currentTime = Math.floor(Date.now() / 1000);
      // //console.log(currentTime)
      // setRedirectLogin(false);
      if (decodedToken?.exp < currentTime) {
        // //console.log("Token expired.");

        dispatch(userUpdate({ user: {}, loggedIn: false }));
        // setRedirectLogin(true);
        dispatch(userToken({ token: '' }));
      }
    } else {
      dispatch(userUpdate({ user: {}, loggedIn: false }));
      // setRedirectLogin(true);
    }
  }, [token])

  useEffect(() => {
    // //console.log('User ', user)
    // //console.log('User token', token)
    if (!user.loggedIn)
      navigate('/login')

  }, [user.loggedIn])




  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestHeaders = {
          "Content-Type": "application/json",
        };

        const response = await fetch(
          `${API_BASE_URL_PAYMENT}${API_ROUTES_PAYMENT.GET_USER_SUBSCRIPTION}/${user?.user?._id}`,
          {
            method: "GET",
            headers: requestHeaders,
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const responseData = await response.json();


        dispatch(userUpdate({ user: { ...user?.user, planName: responseData?.planName, subscriptionStatus: responseData?.subscriptionStatus }, loggedIn: true }));

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);



  const saveToHistory = throttle((state) => {

    if (redoState) {
      setRedoState(false)
    }
    else {

      const newHistory = [...history];
      newHistory[historyIndex + 1] = state.filter(i => i?.name == currentName);





      setHistory(newHistory);
      setHistoryIndex(historyIndex + 1);
    }
  }, 1000)


  const handleFeedBack = () => {
    setFeedback(true)
  }





  useEffect(() => {


    items.length &&
      saveToHistory(items);

  }, [items])

  const handleUndo = () => {


    if (historyIndex > 0) {
      setRedoState(true)



      setShapes(history[historyIndex - 1]?.[0].shapeList)
      setLines(history[historyIndex - 1][0].lineList)
      setTextTags(history[historyIndex - 1][0].textTagList)
      setHistoryIndex(historyIndex - 1)


    }
  };


  const handleRedo = () => {

    if (historyIndex < history.length - 1) {
      setRedoState(true)

      setShapes(history?.[historyIndex + 1]?.[0]?.shapeList)
      setLines(history?.[historyIndex + 1]?.[0]?.lineList)
      setTextTags(history?.[historyIndex + 1]?.[0]?.textTagList)
      setHistoryIndex(historyIndex + 1);

    }
  };


  // const handleDownloadPNG = () => {
  // setSnackbarMessage('Downloading PNG...');
  // setSnackbarSeverity('info');
  // setOpenSnackbar(true);

  //   setTimeout(() => {
  //     var dataURL = stageRef.current.toDataURL({ pixelRatio: 5 });
  //     var link = document.createElement('a');
  //     if (currentLayerData === null) {
  //       link.download = 'untitled.png';
  //     } else {
  //       link.download = `${currentLayerData.name}.png`
  //     }
  //     link.href = dataURL;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     setOpenSnackbar(false);
  //   }, 1000);
  // };

  // const handleDownloadJPG = () => {
  // setSnackbarMessage('Downloading JPG...');
  // setSnackbarSeverity('info');
  // setOpenSnackbar(true);

  //   setTimeout(() => {
  //     var dataURL = stageRef.current.toDataURL({ pixelRatio: 3, mimeType: "image/jpg" });
  //     var link = document.createElement('a');
  //     if (currentLayerData === null) {
  //       link.download = 'untitled.jpg';
  //     } else {
  //       link.download = `${currentLayerData.name}.jpg`
  //     }
  //     link.href = dataURL;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     setOpenSnackbar(false);
  //   }, 1000);
  // };

  const getDataURL = (format, pixelRatio) => {
    const mimeType = format === 'png' ? 'image/png' : 'image/jpeg';
    return stageRef.current.toDataURL({ pixelRatio, mimeType });
  };

  const downloadImageInPhone = async (dataURL, fileName) => {
    try {
      const response = await fetch(dataURL);
      const blob = await response.blob();
      const file = new File([blob], fileName, { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: "Download Image",
          text: "Here is the image you wanted to download.",
        });
        console.log("Image shared successfully");
      } else {
        // Fallback method for devices that do not support Web Share API
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        console.log("Image downloaded using fallback method");
      }
    } catch (error) {
      console.error("Download failed:", error);
      // showSnackbar("Download failed", "error");
      setSnackbarMessage('Download failed...');
      setSnackbarSeverity('info');
      setOpenSnackbar(true);
    }
  };

  const handleDownloadPNG = () => {
    setSnackbarMessage('Downloading PNG...');
    setSnackbarSeverity('info');
    setOpenSnackbar(true);
    setTimeout(() => {
      var dataURL = stageRef.current.toDataURL({ pixelRatio: 10 });
      var link = document.createElement('a');
      const fileName = currentLayerData ? `${currentLayerData.name}.png` : 'untitled.png';
      link.download = fileName;
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Integrate downloadImageInPhone
      downloadImageInPhone(dataURL, fileName);

      setOpenSnackbar(false);
    }, 1000);
  };

  const handleDownloadJPG = () => {
    setSnackbarMessage('Downloading JPG...');
    setSnackbarSeverity('info');
    setOpenSnackbar(true);

    setTimeout(() => {
      const stage = stageRef.current.getStage();

      // Get the dimensions of the Konva stage
      const stageWidth = stage.width();
      const stageHeight = stage.height();

      // Create a temporary canvas element for resizing
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');

      // Set desired dimensions for the temporary canvas
      const newWidth = 800; // Adjust width as needed
      const newHeight = (stageHeight / stageWidth) * newWidth; // Maintain aspect ratio
      tempCanvas.width = newWidth;
      tempCanvas.height = newHeight;

      // Render the Konva stage onto a temporary canvas with the specified pixel ratio
      const stageCanvas = stage.toCanvas({ pixelRatio: 3 });

      // Draw the rendered Konva stage onto the temporary canvas with new dimensions
      tempCtx.drawImage(stageCanvas, 0, 0, stageCanvas.width, stageCanvas.height, 0, 0, newWidth, newHeight);

      // Get data URL from the temporary canvas
      const dataURL = tempCanvas.toDataURL('image/jpeg'); // Specify the image format if needed

      // Create a link element to trigger the download
      const link = document.createElement('a');
      const fileName = currentLayerData ? `${currentLayerData.name}.jpg` : 'untitled.jpg';
      link.download = fileName;
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Integrate downloadImageInPhone if needed
      downloadImageInPhone(dataURL, fileName);

      setOpenSnackbar(false);
    }, 1000);
  };


  const isObjectUpdated = (arr1, arr2) => {
    return arr1?.length === arr2?.length && arr1?.every((obj, index) => obj === arr2[index]);
  };
  useEffect(() => {
    const updatedItem = {
      ...currentLayerData,
      textTagList: textTags,
      shapeList: shapes,
      lineList: lines,
    };



    setItems((prevItems) => prevItems.map((item, i) => {
      if (item?.id === currentLayerData?.id) {
        return updatedItem
      } else {
        return item
      }
    }))


  }, [lines, shapes, textTags, currentLayerData])





  const checkIfDrawerEmpty = async () => {
    if (items.length === 0) {

      const newItem = {
        id: uuidv4(),
        name: 'Untitled',
        backgroundImage: backgroundImage,
        textTagList: [],
        shapeList: [],
        lineList: [],
      };
      const requestHeaders = {
        "Content-Type": "application/json",
      };
      await fetch(
        API_BASE_URL + API_ROUTES_GENERAL.SAVE_PLAY + `/${user?.user?._id}`,
        {
          method: "GET",
          headers: requestHeaders,
        }
      )
        .then((response) => response.json())
        .then((response) => {



          if (response?.data && response?.data.length != 0) {
            console.log("response?.data===>", response?.data[0]?.width, response?.data[0]?.height)
            // console.log("current screen===>", imageRef.current.attrs)
            setItems(response?.data ? response?.data : [newItem]);
            setTextTags(response?.data[0]?.textTagList ? response?.data[0]?.textTagList : newItem?.textTagList);
            setShapes(response?.data[0]?.shapeList ? response?.data[0]?.shapeList : newItem?.shapeList);
            setLines(response?.data[0]?.lineList ? response?.data[0]?.lineList : newItem?.lineList);
            setCurrentLayerData(response?.data[0] ? response?.data[0] : newItem);
            setCurrentName(response?.data[0]?.name ? response?.data[0]?.name : newItem?.name)
            setOldDimension({ width: response?.data[0]?.width ?? 0, height: response?.data[0]?.height ?? 0 })

          }
          else {

            setItems([newItem]);
            setTextTags(newItem?.textTagList);
            setShapes(newItem?.shapeList);
            setLines(newItem?.lineList);
            setCurrentLayerData(newItem);
            setCurrentName(newItem?.name)
          }

        })
        .catch((error) => {


          setItems([newItem]);
          setTextTags(newItem?.textTagList);
          setShapes(newItem?.shapeList);
          setLines(newItem?.lineList);
          setCurrentLayerData(newItem);
          setCurrentName(newItem?.name)


        });


    }
  };
  const handleDeleteAll = () => {
    deleteAllShapes();
    deleteAllTextTags();
    deleteAllLines();
  };

  const handleDeleteDefenseFormation = () => {
    setShapes(shapes.filter(shape => !shape.formationType.toLowerCase().startsWith('defense')));
  }
  const handleDeleteOffenseFormation = () => {
    setShapes(shapes.filter(shape => !shape.formationType.toLowerCase().startsWith('offense')));
  }
  const handleDeleteAllTextTags = () => {
    deleteAllTextTags();
  };

  const handleDeleteAllLines = () => {
    deleteAllLines();
  };

  const handleToggleSpeedDial = () => {
    setIsSpeedDialOpen(!isSpeedDialOpen);
  };


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = () => {
    dialogAction(dialogText);
    closeDialog();
  };
  const handleSignOut = async () => {
    try {
      setCurrentUser(null);
      setShowAuthenticator(!showAuthenticator);
      await signOut();
    } catch (error) {

    }
  }

  const handleNameUpdate = (updatedName) => {
    if (updatedName !== null) {
      const itemExists = items.some((item, i) => item.name === updatedName);
      if (itemExists) {
        setSnackbarMessage('A play with this name already exists.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } else if (updatedName === '') {
        setSnackbarMessage('Please enter a name for the play.');
        setSnackbarSeverity('warning');
        setOpenSnackbar(true);
      } else {
        const deepCopyTextTags = _.cloneDeep(textTags).map(tag => ({ ...tag, id: uuidv4() }));

        let shapeIdMapping = {};
        const deepCopyShapes = _.cloneDeep(shapes).map(shape => {
          const newId = uuidv4();
          shapeIdMapping[shape.id] = newId;
          return { ...shape, id: newId };
        });

        let lineIdMapping = {};
        const deepCopyLines = _.cloneDeep(lines).map(line => {
          const newId = uuidv4();
          lineIdMapping[line.id] = newId;
          return { ...line, id: newId, attachedShapeId: shapeIdMapping[line.attachedShapeId] };
        });
        const deepCopyLinesAgain = _.cloneDeep(deepCopyLines).map(line => {
          return { ...line, drawnFromId: lineIdMapping[line.drawnFromId] || line.drawnFromId };
        });

        const updatedItem = {
          ...currentLayerData,
          name: updatedName,
          textTagList: deepCopyTextTags,
          shapeList: deepCopyShapes,
          lineList: deepCopyLinesAgain,
        };

        setItems((prevItems) => prevItems.map((item, i) => i.name === currentLayerData.name ? updatedItem : item));
        setTextTags(updatedItem.textTagList);
        setShapes(updatedItem.shapeList);
        setLines(updatedItem.lineList);
        setCurrentLayerData(updatedItem);
        setSelectedItem(updatedItem.id);
        setSnackbarMessage('Play Updated Successfully.');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
    }
  }

  const actions = [
    { name: "Delete All", icon: <BsTrash3Fill size={20} />, action: handleDeleteAll },
    { name: "Download PNG", icon: < PiFilePng size={25} />, action: handleDownloadPNG },
    { name: "Download JPG", icon: < PiFileJpg size={25} />, action: handleDownloadJPG },
    { name: "Delete Offense Formation", icon: <FlashOffIcon size={30} />, action: handleDeleteOffenseFormation },
    { name: "Delete Defense Formation", icon: <RemoveModeratorIcon />, action: handleDeleteDefenseFormation },
    { name: "Delete All Text Tags", icon: <FontDownloadOffIcon />, action: handleDeleteAllTextTags },
    { name: "Delete All Lines", icon: <AiOutlineDeleteColumn size={20} />, action: handleDeleteAllLines },

  ];


  const handleMouseEnter = (index) => {
    const timeoutId = setTimeout(() => {
      setTooltipOpen(prevState => ({ ...prevState, [index]: true }));
    }, 400);
    setTooltipTimeoutId(timeoutId);
  };

  const handleMouseLeave = (index) => {
    clearTimeout(tooltipTimeoutId);
    const timeoutId = setTimeout(() => {
      setTooltipOpen(prevState => ({ ...prevState, [index]: false }));
    }, 300);
    setTooltipTimeoutId(timeoutId);
  };



  const openDialog = (title, text, action) => {

    setDialogTitle(title);
    setDialogText(text);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  useEffect(() => {
    checkIfDrawerEmpty()
  }, [])


  useEffect(() => {
    currentLayerData &&
      setCurrentName(currentLayerData?.name)
    setRedoState(false)

  }, [currentLayerData])
  const [open, setOpen] = React.useState(Array(items.length).fill(false));


  useEffect(() => {
    if (open.length !== items.length) {
      setOpen(Array(items.length).fill(false))
    }
  }, [items])
  const handleOnClickAddPlay = () => {



    openDialog('Add Play', '', (newPlayName) => {

      if (newPlayName !== null) {
        const itemExists = items?.some(item => item?.name === newPlayName);
        if (itemExists) {
          setSnackbarMessage('A play with this name already exists.');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        } else if (newPlayName === '') {
          setSnackbarMessage('Please enter a Play Name.');
          setSnackbarSeverity('warning');
          setOpenSnackbar(true);
        } else {
          const newItem = {
            id: uuidv4(),
            name: newPlayName,
            backgroundImage: backgroundImage,
            textTagList: [],
            shapeList: [],
            lineList: [],

          };

          logEvent(analytics, `Play Added ${newItem}`);
          setItems((prevItems) => [...prevItems, newItem]);
          setTextTags(newItem?.textTagList);
          setShapes(newItem?.shapeList);
          setLines(newItem?.lineList);
          setCurrentLayerData(newItem);

          setSnackbarMessage('Play Added Successfully.');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
        }
      }
    });
  }

  const handleItemClick = (text) => {

    setSelectedItem(text?.id);
    setHistory([])
    setHistoryIndex(-1)
    const playName = text?.name;
    setCurrentName(playName)

    const item = items.find(item => item?.name === playName);
    console.log("Item on Click==>", item)
    setOldDimension({ width: item?.width, height: item.height })
    const layerData = _.cloneDeep(item);

    if (currentLayerData.name != layerData.name) {
      setCurrentLayerData(layerData)
      setCurrentName(playName)


      setTextTags(layerData?.textTagList);
      setShapes(layerData?.shapeList);
      setLines(layerData?.lineList);
    }


  };


  const removeItem = async (index) => {
    if (items.length > 1) {
      const updatedItems = items.filter((item, i) => i !== index);
      setItems(updatedItems);

      const newIndex = index === 0 ? 0 : index - 1;

      handleItemClick(updatedItems[newIndex])
      const itemToDelete = updatedItems[newIndex];




      const response = await fetch(API_BASE_URL + API_ROUTES_GENERAL.SAVE_PLAY + `/${itemToDelete?._id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      await response.json();
      setSnackbarMessage('Play Removed.');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
    } else {
      setSnackbarMessage('Play can not be Removed.');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
    }
  };

  const updateItem = (index) => {
    setSelectedTextTags([]);
    openDialog('Update Play', items[index]?.name, (updatedName) => {
      if (updatedName !== null) {
        const itemExists = items.some((item, i) => item.name === updatedName && i !== index);
        if (itemExists) {
          setSnackbarMessage('A play with this name already exists.');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        } else if (updatedName === '') {
          setSnackbarMessage('Please enter a name for the play.');
          setSnackbarSeverity('warning');
          setOpenSnackbar(true);
        } else {
          const deepCopyTextTags = _.cloneDeep(textTags).map(tag => ({ ...tag, id: uuidv4() }));

          let shapeIdMapping = {};
          const deepCopyShapes = _.cloneDeep(shapes).map(shape => {
            const newId = uuidv4();
            shapeIdMapping[shape.id] = newId;
            return { ...shape, id: newId };
          });

          let lineIdMapping = {};
          const deepCopyLines = _.cloneDeep(lines).map(line => {
            const newId = uuidv4();
            lineIdMapping[line.id] = newId;
            return { ...line, id: newId, attachedShapeId: shapeIdMapping[line.attachedShapeId] };
          });
          const deepCopyLinesAgain = _.cloneDeep(deepCopyLines).map(line => {
            return { ...line, drawnFromId: lineIdMapping[line.drawnFromId] || line.drawnFromId };
          });

          const updatedItem = {
            ...items[index],
            name: updatedName,
            textTagList: deepCopyTextTags,
            shapeList: deepCopyShapes,
            lineList: deepCopyLinesAgain,
          };

          setItems((prevItems) => prevItems.map((item, i) => i === index ? updatedItem : item));
          setTextTags(updatedItem.textTagList);
          setShapes(updatedItem.shapeList);
          setLines(updatedItem.lineList);
          setCurrentLayerData(updatedItem);
          setSelectedItem(updatedItem.id);
          setSnackbarMessage('Play Updated Successfully.');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
        }
      }
    });
  };

  const saveItem = async (index) => {
    try {
      // console.log("imageRef.current===>", imageRef.current.attrs.width)
      // return
      const updatedItem = {
        ...items[index],
        textTagList: textTags,
        shapeList: shapes,
        lineList: lines,
        width: imageRef?.current?.attrs?.width,
        height: imageRef?.current?.attrs?.height
      };

      const requestHeaders = {
        "Content-Type": "application/json",
      };
      const response = await fetch(
        API_BASE_URL + API_ROUTES_GENERAL.SAVE_PLAY,
        {
          method: "POST",
          headers: requestHeaders,
          body: JSON.stringify({ ...updatedItem, userId: user?.user?._id }),
        }
      )
      await response.json()


      setItems((prevItems) => prevItems.map((item, i) => i === index ? updatedItem : item));
      setSelectedItem(updatedItem.id);
      setSnackbarMessage('Play Saved Successfully.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

    } catch (e) { console.log(e) }
  }

  const duplicateItem = (text, index) => {

    var newPlayName = currentLayerData.name + ' (duplicated)';
    const itemExists = items?.some(item => item?.name === newPlayName);
    if (itemExists) {
      newPlayName = newPlayName + "1"
    }



    const duplicatedShapes = _.cloneDeep(shapes.map(shape => ({ ...shape })));
    const duplicatedLines = _.cloneDeep(lines.map(line => ({ ...line })));
    const duplicatedTextTags = _.cloneDeep(textTags.map(textTags => ({ ...textTags })));

    const newItem = {
      id: uuidv4(),
      name: newPlayName,
      backgroundImage: currentLayerData.backgroundImage,
      textTagList: duplicatedTextTags,
      shapeList: duplicatedShapes,
      lineList: duplicatedLines,
    };

    setItems((prevItems) => [...prevItems, newItem]);
    setTextTags(newItem?.textTagList);
    setShapes(newItem?.shapeList);
    setLines(newItem?.lineList);
    setCurrentLayerData(newItem);

    setSnackbarMessage('Play Duplicated Successfully.');
    setSnackbarSeverity('success');
    setOpenSnackbar(true);

  }

  const handleMenuItemClick = (text, option, index) => {
    switch (option) {
      case 'Rename': {
        logEvent(analytics, `Play Rename`);

        updateItem(index);


        break;
      }
      case 'Delete': {
        logEvent(analytics, `Play Delete`);

        removeItem(index);


        break;
      }
      case 'Save': {
        logEvent(analytics, `Play Save`);
        saveItem(index, text.name);

        break;
      }
      case 'Duplicate':
        {
          logEvent(analytics, `Play Duplicate`);

          duplicateItem(text, index);
          break;
        }
      default:
        break;
    }
  };


  return (
    <>
      <ThemeProvider theme={theme}>
        <StageDimensionsContext.Provider value={{ stageDimensions }}>
          <div className=' noscroll'>
            <Header
              Navigate={navigate}
              isOpen={(open) => { setPoperOpen(open) }}
              Redo={handleRedo}
              Undo={handleUndo}
              disableCursor={false}
              title={currentName ?? ''}
              NameUpdate={handleNameUpdate}
              handleDeleteAll={handleDeleteAll}
              handleDownloadPNG={handleDownloadPNG}
              handleDownloadJPG={handleDownloadJPG}
              disableView={false}
              clearFormation={handleDeleteAll}
            />
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '0vw',
              padding: '0vw',
              height: '90vh',
              width: '100%',
            }}>
              <div className="custom-scrollbar noscroll">
                <Stencil
                  shapes={shapes}
                  setShapes={setShapes}
                  textTags={textTags}
                  setTextTags={setTextTags}
                  setSelectedTextTags={setSelectedTextTags}
                  currentLayerData={currentLayerData}
                  setCurrentLayerData={setCurrentLayerData}
                  onAddFormation={addFormation}
                  onAddShape={addShape}
                  onAddTextTag={addTextTag}
                  fieldType={fieldType}
                  setFieldType={setFieldType}
                  setZone={setZone}
                  zone={zone}
                  setRedLine={setRedLine}
                  redLine={redLine}
                  onDeleteAllShapes={deleteAllShapes}
                  onChangeFormation={deleteFormation}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  setSelectedTagColor={setSelectedTextColor}
                  selectedTagColor={selectedTextColor}
                  selectedLineColor={selectedLineColor}
                  setSelectedLineColor={setSelectedLineColor}
                  selectedLineStroke={selectedLineStroke}
                  setSelectedLineStroke={setSelectedLineStroke}
                  selectedLineEnd={selectedLineEnd}
                  setSelectedLineEnd={setSelectedLineEnd}
                  onDeleteAllTextTags={deleteAllTextTags}
                  onDeleteAllLines={deleteAllLines}
                  setColorButtonPressCount={setColorButtonPressCount}
                  setStrokeTypeButtonPressCount={setStrokeTypeButtonPressCount}
                  setStrokeEndButtonPressCount={setStrokeEndButtonPressCount}
                  selectedColorButton={selectedColorButton}
                  setSelectedColorButton={setSelectedColorButton}
                  stageRef={stageRef}
                  flipAllTextTags={flipAllTextTags}
                  flipAllShapes={flipAllShapes}
                  backgroundImage={backgroundImage}
                  lines={lines}
                  setLines={setLines}
                  items={items}

                  setFeedback={feedBack}
                  dialogOpen={dialogOpen}
                  setDialogOpen={setDialogOpen}
                  dialogTitle={dialogTitle}
                  setDialogTitle={setDialogTitle}
                  dialogText={dialogText}
                  setDialogText={setDialogText}
                  dialogAction={dialogAction}
                  setDialogAction={setDialogAction}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  openSnackbar={openSnackbar}
                  setOpenSnackbar={setOpenSnackbar}
                  snackbarMessage={snackbarMessage}
                  setSnackbarMessage={setSnackbarMessage}
                  snackbarSeverity={snackbarSeverity}
                  setSnackbarSeverity={setSnackbarSeverity}
                  openDialog={openDialog}

                />
              </div>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 1.8,
                position: "relative",
                maxWidth: 'calc(80%)',
                marginRight: '0vw',
                borderTop: '1px solid black',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
                height: '100%',
                paddingTop: '1.5vw',
                backgroundColor: '#1E1E1E'

              }}>

                <Canvas
                  imageRef={imageRef}
                  selectedLineID={selectedLineID}
                  setSelectedLineID={setSelectedLineID}
                  currentLayerData={currentLayerData}
                  setCurrentLayerData={setCurrentLayerData}
                  colorButtonPressCount={colorButtonPressCount}
                  strokeTypeButtonPressCount={strokeTypeButtonPressCount}
                  setStrokeTypeButtonPressCount={setStrokeTypeButtonPressCount}
                  strokeEndButtonPressCount={strokeEndButtonPressCount}
                  setStrokeEndButtonPressCount={setStrokeEndButtonPressCount}
                  selectedLineEnd={selectedLineEnd}
                  setSelectedLineEnd={setSelectedLineEnd}
                  lines={lines}
                  setShapes={setShapes}
                  setSelectedTagColor={setSelectedTextColor}
                  selectedTagColor={selectedTextColor}
                  setLines={setLines}
                  setSelectedColor={setSelectedColor}
                  startPos={startPos}
                  selectedLineColor={selectedLineColor}
                  setSelectedLineColor={setSelectedLineColor}
                  endPos={endPos}
                  startDrawing={startDrawing}
                  draw={draw}
                  stopDrawing={stopDrawing}
                  deleteAllLines={deleteAllLines}
                  selectedColorButton={selectedColorButton}
                  setSelectedColorButton={setSelectedColorButton}
                  onLineChange={updateLine}
                  onLineDelete={deleteLine}
                  shapes={shapes}
                  selectedShapes={selectedShapes}
                  setSelectedShapes={setSelectedShapes}
                  onShapeChange={updateShape}
                  onShapeDelete={deleteShape}
                  onHideContextMenu={hideShapeContextMenu}
                  textTags={textTags}
                  selectedTextTags={selectedTextTags}
                  setSelectedTextTags={setSelectedTextTags}
                  onTextTagChange={updateTextTag}
                  onTextTagDelete={deleteTextTag}
                  onHideTextTagContextMenu={hideTextTagContextMenu}
                  selectedColor={selectedColor}
                  selectedLineStroke={selectedLineStroke}
                  backgroundImage={backgroundImage}
                  setStageDimensions={setStageDimensions}
                  stageRef={stageRef}
                  waterMark={waterMark}
                  setWatermark={setWatermark}
                  oldDimensions={oldDimensions}
                  setOldDimensions={setOldDimension}
                />
                <ScrollTabBar
                  data={items}
                  handleMenuItemClick={handleMenuItemClick}
                  handleOnClickAddPlay={handleOnClickAddPlay}
                  handleItemClick={handleItemClick}
                  currentLayerData={currentLayerData}
                />
                {/* {!popperOpen && <SpeedDial
                  ariaLabel="SpeedDial"
                  icon={isSpeedDialOpen ? <CloseIcon sx={{ color: 'red' }} /> : <MoreVertIcon color='black' sx={{ color: 'black' }} />}
                  direction={'down'}
                  FabProps={{ size: 'small', color: 'white' }}
                  onClick={handleToggleSpeedDial}
                  open={isSpeedDialOpen}
                  className='z-10'
                  sx={{ position: 'absolute', top: '70px', right: '40px', }}
                >
                  {actions.map((action, index) => (
                    <SpeedDialAction
                      key={`dial-${index}`}
                      icon={action.icon}
                      onClick={action.action}
                      tooltipTitle={tooltipOpen[index] ? action.name : ""}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    />
                  ))}
                </SpeedDial>
                } */}
                <Dialog open={dialogOpen} onClose={closeDialog}>
                  <div className="" style={bg}>
                    <DialogTitle style={border}>{dialogTitle}</DialogTitle>
                    <DialogContent style={border}>
                      <TextField
                        autoFocus
                        style={border}
                        margin="dense"
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: border.color
                          },
                          '& .MuiInputBase-input': {
                            color: border.color
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: border.color,
                            opacity: 0.7
                          },
                          '& .MuiInputLabel-notchedroot': {
                            color: '#FFFFFF'
                          },
                          '& .MuiInputLabel-root:hover': {
                            color: '#FFFFFF'
                          },
                          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline ': {
                            borderColor: '#FFFFFF'
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white'
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: 'white'
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFFFFF'
                          },
                        }}
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={dialogText}
                        onChange={(event) => setDialogText(event.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeDialog}>Cancel</Button>
                      <Button onClick={handleDialogSubmit} type="submit" label="Submit" style={{ backgroundColor: "#1565C0", borderRadius: "4px", color: "white", fontWeight: "medium", padding: "5px 15px" }}>
                        Submit
                      </Button>
                    </DialogActions>
                  </div>
                </Dialog>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                  <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%', marginTop: 40 }}>
                    {snackbarMessage}
                  </MuiAlert>
                </Snackbar>

              </div>
            </div>
          </div>
        </StageDimensionsContext.Provider>
      </ThemeProvider >
    </>
  );
}


export default App;