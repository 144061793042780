import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Wrapper from '../components/Wrapper';
import { useNavigate } from 'react-router-dom';
import AnimateButton from '../components/AnimateButton';
import { useDispatch } from 'react-redux';
import { userToken, userUpdate } from '../redux/slices/userSlice';
import { Loader } from '../components/Loader';
import { toast } from 'react-toastify';
import { API_BASE_URL_AUTH, API_ROUTES_AUTH } from '../constants';
const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};
const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [lightMode, setLightMode] = useState(false);
    const handleLogin = async () => {
        let isValid = true;
        setEmailError('');
        setPasswordError('');
        if (!validateEmail(email)) {
            setEmailError('Invalid email address.');
            isValid = false;
        }
        if (!regularExpression.test(password)) {
            setPasswordError('Password must be 8-16 characters long and include at least one number and one special character.');
            isValid = false;
        }
        if (!isValid) return;
        setLoader(true);
        try {

            const requestBody = {
                email: email,
                password: password
            };
            //console.log("Login")

            await fetch(API_BASE_URL_AUTH + API_ROUTES_AUTH.LOGIN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            }).then(response => response.json()) // Parse response body as JSON
                .then(data => {
                    if (data?.success) {
                        toast.success('Signed In successfully.');
                        dispatch(userUpdate({ user: data?.userData, loggedIn: true }));
                        dispatch(userToken({ token: data.token }))
                        if (data?.userData.role == 'admin')
                            navigate('/admin/billing');
                        else
                            navigate('/diagram');

                    }
                    else {
                        toast.error(data?.message);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    toast.error(error.message);
                });




        } catch (e) {
            //console.log(e)
            toast.error('Invalid User Name or Password.');
        } finally {
            setLoader(false);
        }
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const textStyle = { color: lightMode ? 'black' : 'white' };
    const border = { color: lightMode ? 'black' : 'white' };
    return (
        <Wrapper>
            <div className={`lg:w-[40%] ${lightMode ? "bg-white" : "bg-[#1E1E1E]"}  flex items-center flex-col w-full px-5 sm:px-20 lg:px-28 justify-center`}>
                <div className='w-full'>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <p className={`text-[24px]  font-bold`} style={border}>Login</p>
                    </Stack>
                    <div className='mt-5'>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="email-login" className='font-inter  text-white text-[15px]' style={border}>Email Address</InputLabel>
                            <OutlinedInput
                                id="email-login"
                                type="email"
                                name="email"
                                size='small'
                                clas
                                className='placeholder:text-white'
                                placeholder="Enter email address"
                                fullWidth
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                error={!!emailError}
                                onKeyDown={(e) => {
                                    if (e.key == 'Enter')
                                        handleLogin()
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: border.color
                                    },
                                    '& .MuiInputBase-input': {
                                        color: textStyle.color
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: textStyle.color,
                                        opacity: 0.7 // Adjust the opacity for better visibility
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: lightMode ? inputBorderColor : 'white'
                                    }
                                }}
                            />
                            {emailError && <Typography color="error">{emailError}</Typography>}
                        </Stack>
                    </div>
                    <div className='mt-5'>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="password-login" className='font-inter text-white text-[15px]' style={border}>Password</InputLabel>
                            <OutlinedInput
                                id="password-login"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                size='small'
                                className='placeholder:text-white'
                                placeholder="Enter password"
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: border.color
                                    },
                                    '& .MuiInputBase-input': {
                                        color: textStyle.color
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: textStyle.color,
                                        opacity: 0.7 // Adjust the opacity for better visibility
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: lightMode ? inputBorderColor : 'white'
                                    }
                                }}
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                onKeyDown={(e) => {
                                    if (e.key == 'Enter')
                                        handleLogin()
                                }}
                                error={!!passwordError}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility style={border} /> : <VisibilityOff style={border} />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {passwordError && <Typography color="error">{passwordError}</Typography>}
                        </Stack>
                    </div>
                    <div className='text-start font-inter  mt-4 text-white text-[15px] cursor-pointer hover:underline' style={border} onClick={() => navigate('/forgot-password')}>
                        Forgot Password?
                    </div>
                    <div className='mt-5'>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button fullWidth style={{ backgroundColor: "#2B76BA" }} className='mt-5' size="large" type="submit" variant="contained" color="primary" onClick={handleLogin}>
                                    <Box color="white" className="font-inter font-semibold tracking-widest" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                        {loader ? <Loader /> : <p className=' tracking-[4px] w-[70px]'>Enter</p>}
                                    </Box>
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </div>
                    <p onClick={() => navigate('/register')} className='font-inter text-[15px] mt-4 text-white' style={{ color: '#E1F2FB' }}>
                        Don't have an account? <span style={{ cursor: 'pointer', color: '#2B76BA' }} className='underline'>Sign Up now</span>
                    </p>
                </div>
            </div>
        </Wrapper>
    );
};
export default Login;