
import React, { useState } from 'react';
import { Ellipse, Group, Rect } from 'react-konva';
import ContextMenu from '../../menus/ContextMenu';

const LinemanOval = (props) => {
    const {
        id,
        startDrawing,
        setIsMouseDownOnAnchor,
        shapeRef,
        position,
        initialColor,
        showContextMenu,
        contextMenuPosition,
        handleRightClick,
        handleDeleteClick,
        handleDragStart,
        handleDragMove,
        handleDragEnd,
        handleHideContextMenu,
        ellipseRadiuses,
        dragBoundFunc,
        selectedShapeID,
        shiftPressed,
        setSelectedShapeID,
        setHasBeenSelected,
        hasBeenSelected,
        transformed, selectedColor
    } = props;


    const isSelected = (selectedShapeID && selectedShapeID?.find?.(i => i === id)) ?? false;

    const haloRadiuses = { x: ellipseRadiuses.x + 8, y: ellipseRadiuses.y + 8 };
    const strokeOptions = { color: 'black', strokeWidth: 2 };
    const centerLineWidth = 3.5;

    const states = [
        { leftState: 0, rightState: 200 },
        { leftState: 0, rightState: -1 },
        { leftState: 0, rightState: 1 },
        { leftState: 0, rightState: 0 }
    ];

    const lineStates = [
        180,
        270,
        315,
        360,
        405,
        450,
    ];
    const [isDrawing, setIsDrawing] = useState(false);

    const [stateIndex, setStateIndex] = useState(0);
    const [lineIndex, setLineIndex] = useState(0);
    const [state, setState] = useState({ colorState: states[stateIndex], lineState: lineStates[lineIndex] });

    const handleLinemanClick = () => {





        if (shiftPressed) {

            setSelectedShapeID([...selectedShapeID, id]);
            transformed(true)


        } else {
            transformed(false)

            setSelectedShapeID([id]);

        }



        if (hasBeenSelected && selectedShapeID?.includes(id)) {
            const newIndex = (stateIndex + 1) % states.length;
            setStateIndex(newIndex);
            setState({ colorState: states[newIndex], lineState: lineStates[lineIndex] });
        }


        if (!hasBeenSelected) {
            setHasBeenSelected(true);
        }
    };

    const handleTouchStart = (e) => {
        const touchPos = e.target.getStage().getPointerPosition();
        startDrawing(touchPos, id, null, position);
        setIsMouseDownOnAnchor(true);
        setIsDrawing(true);
        e.cancelBubble = true;
    };

    const handleTouchMove = (e) => {
        if (!isDrawing) return;
        const touchPos = e.target.getStage().getPointerPosition();
        handleDragMove(e, touchPos);
    };

    const handleTouchEnd = (e) => {
        setIsDrawing(false);
        handleDragEnd(e);
    };
    return (
        <>
            <Group
                ref={shapeRef}
                draggable={isSelected ? true : false}
                dragBoundFunc={dragBoundFunc}
                onDragStart={handleDragStart}
                onDragMove={handleDragMove}
                onDragEnd={handleDragEnd}

                onTouchStart={handleDragStart}
                onContextMenu={handleRightClick}
                x={position.x}
                y={position.y}
            >
                {isSelected && (
                    <Ellipse
                        x={0}
                        y={0}
                        fill="white"
                        radiusX={haloRadiuses.x}
                        radiusY={haloRadiuses.y}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        strokeWidth={2}
                        shadowBlur={15}
                        shadowColor='#184267'
                        onMouseDown={(e) => {
                            const startPos = e.target.getStage().getPointerPosition();

                            startDrawing(startPos, id, null, position);
                            setIsMouseDownOnAnchor(true);
                            e.cancelBubble = true;
                        }}
                        onMouseEnter={(e) => {
                            const container = e.target.getStage().container();


                            container.style.cursor = 'crosshair';
                        }}
                        onMouseLeave={(e) => {
                            const container = e.target.getStage().container();
                            container.style.cursor = 'default';
                        }}
                    />
                )}
                <Ellipse
                    x={0}
                    y={0}
                    radiusX={ellipseRadiuses.x}
                    radiusY={ellipseRadiuses.y}
                    stroke={strokeOptions.color}
                    strokeWidth={strokeOptions.strokeWidth}
                    onClick={handleLinemanClick}
                    onTap={handleLinemanClick}
                    fillLinearGradientStartPoint={{ x: state?.colorState?.leftState, y: 0 }}
                    fillLinearGradientEndPoint={{ x: state?.colorState?.rightState, y: 0 }}
                    fillLinearGradientColorStops={[0, initialColor == undefined ? "#FFFFFF" : initialColor, 1, 'black']}
                />
                {stateIndex === 3 && (
                    <Rect
                        x={-1.6}
                        y={-ellipseRadiuses.y}
                        onTap={handleLinemanClick}
                        onClick={handleLinemanClick}
                        width={centerLineWidth}
                        height={ellipseRadiuses.y * 2}
                        fill="black"
                    />
                )}

            </Group>
            {showContextMenu && <ContextMenu position={contextMenuPosition} onDelete={handleDeleteClick} onMouseLeave={handleHideContextMenu} />}
        </>
    );
}

export default LinemanOval;