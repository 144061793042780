import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Wrapper from '../components/Wrapper';
import { generateClient } from 'aws-amplify/api';
import '../App.css';
import { Loader } from '../components/Loader';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';
import { API_BASE_URL_AUTH, API_ROUTES_AUTH } from '../constants';
import { useDispatch } from 'react-redux';
import { signUpdata } from '../redux/slices/userSlice';
import AnimateButton from '../components/AnimateButton';

const SignUp = () => {
    const client = generateClient();
    const navigate = useNavigate();
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    const [lightMode, setLightMode] = useState(false);

    const dispatch = useDispatch();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSignUp = async () => {
        const validationErrors = {};

        if (!fName) validationErrors.fName = "First name is required";
        if (!lName) validationErrors.lName = "Last name is required";
        if (!email) {
            validationErrors.email = "Email is required";
        } else if (!validateEmail(email)) {
            validationErrors.email = "Email is invalid";
        }
        if (!password) validationErrors.password = "Password is required";
        if (!regularExpression.test(password)) {
            validationErrors.password =
                "Password must be 8-16 characters long and include at least one number and one special character.";
        }
        if (password !== cPassword) validationErrors.cPassword = "Passwords do not match";

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        setLoading(true)

        try {
            const userDetails = {
                firstName: fName,
                lastName: lName,
                email: email,
                stripe_customer_id: '',
                role: 'user',
                password
        };

            
            

            const requestBody = {
                email
            };
            const requestHeaders = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Headers": '*'
            };
            
            

            await fetch(API_BASE_URL_AUTH + API_ROUTES_AUTH.REGISTER_OTP, {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(requestBody)
            })
                .then((response) => response.json())
                .then(response => {
                    setLoading(false)
                    if (response?.success) {
                        toast.success(response?.message)
                        dispatch(signUpdata(userDetails))
                        navigate('/verification');
                    } else {
                        toast.error(response?.message)
                    }
                }).catch((error) => {
                    
                    toast.error(error.message)
                    setLoading(false)
                })
        } catch (error) {
            
            toast.error(error.message)
            setLoading(false)
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowCPassword = () => {
        setShowCPassword(!showCPassword);
    };

    const handleMouseDownCPassword = (event) => {
        event.preventDefault();
    };
    const textStyle = { color: lightMode ? 'black' : 'white' };
    const border = { color: lightMode ? 'black' : 'white' };


    return (
        <Wrapper>
            <div className={`lg:w-[40%] flex items-center flex-col w-full px-5 ${lightMode ? "bg-white" : "bg-[#1E1E1E]"} sm:px-20 justify-center`}>
                <div className='w-full'>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <Typography variant="h5" component="p" style={border} fontWeight="bold">Sign Up</Typography>
                        <Typography component="p"
                            style={{ cursor: 'pointer', color: '#2B76BA' }}
                            className='hover:underline' onClick={() => navigate('/login')}>Already have an account?</Typography>
                    </Stack>
                    <div className='flex justify-between items-center'>
                        <div className='mt-5 relative w-[45%]'>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="f-name" style={border}>First name</InputLabel>
                                <OutlinedInput
                                    id="f-name"
                                    type="text"
                                    name="fname"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: border.color
                                        },
                                        '& .MuiInputBase-input': {
                                            color: textStyle.color
                                        },
                                        '& .MuiInputBase-input::placeholder': {
                                            color: textStyle.color,
                                            opacity: 0.7 
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: lightMode ? inputBorderColor : 'white'
                                        }
                                    }}
                                    size='small'
                                    placeholder="First name"
                                    fullWidth
                                    onChange={(e) => setFName(e.target.value)}
                                    value={fName}
                                    error={!!errors.fName}
                                    onKeyDown={(e) => {
                                        if (e.key == 'Enter')
                                            handleSignUp()
                                    }}

                                />
                            </Stack>
                            <div className='absolute left-0 top-[70px]'>{errors.fName && <Typography color="error">{errors.fName}</Typography>}</div>
                        </div>
                        <div className='mt-5 relative w-[45%]'>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="l-name" style={border}>Last Name</InputLabel>
                                <OutlinedInput
                                    id="l-name"
                                    type="text"
                                    name="lname"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: border.color
                                        },
                                        '& .MuiInputBase-input': {
                                            color: textStyle.color
                                        },
                                        '& .MuiInputBase-input::placeholder': {
                                            color: textStyle.color,
                                            opacity: 0.7 
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: lightMode ? inputBorderColor : 'white'
                                        }
                                    }}
                                    size='small'
                                    placeholder="Last Name"
                                    fullWidth
                                    onChange={(e) => setLName(e.target.value)}
                                    value={lName}
                                    error={!!errors.lName}
                                    onKeyDown={(e) => {
                                        if (e.key == 'Enter')
                                            handleSignUp()
                                    }}

                                />
                            </Stack>
                            <div className='absolute left-0 top-[70px]'> {errors.lName && <Typography color="error">{errors.lName}</Typography>}</div>
                        </div>
                    </div>

                    <div className='mt-7'>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="email-login" style={border}>Email Address</InputLabel>
                            <OutlinedInput
                                id="email-login"
                                type="email"
                                name="email"
                                size='small'
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: border.color
                                    },
                                    '& .MuiInputBase-input': {
                                        color: textStyle.color
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: textStyle.color,
                                        opacity: 0.7 
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: lightMode ? inputBorderColor : 'white'
                                    }
                                }}
                                placeholder="Enter email address"
                                fullWidth
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                error={!!errors.email}
                                onKeyDown={(e) => {
                                    if (e.key == 'Enter')
                                        handleSignUp()
                                }}

                            />
                            {errors.email && <Typography color="error">{errors.email}</Typography>}
                        </Stack>
                    </div>
                    <div className='mt-5'>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="password" style={border}>Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                size='small'
                                placeholder="Enter Password"
                                fullWidth
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: border.color
                                    },
                                    '& .MuiInputBase-input': {
                                        color: textStyle.color
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: textStyle.color,
                                        opacity: 0.7 
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: lightMode ? inputBorderColor : 'white'
                                    }
                                }}
                                error={!!errors.password}
                                onKeyDown={(e) => {
                                    if (e.key == 'Enter')
                                        handleSignUp()
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility style={border} /> : <VisibilityOff style={border} />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {errors.password && <Typography color="error">{errors.password}</Typography>}
                        </Stack>
                    </div>
                    <div className='mt-5'>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="confir_password" style={border}>Confirm Password</InputLabel>
                            <OutlinedInput
                                id="confir_password"
                                type={showCPassword ? 'text' : 'password'}
                                name="cPassword"
                                size='small'
                                placeholder="Confirm Password"
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: border.color
                                    },
                                    '& .MuiInputBase-input': {
                                        color: textStyle.color
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: textStyle.color,
                                        opacity: 0.7 
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: lightMode ? inputBorderColor : 'white'
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == 'Enter')
                                        handleSignUp()
                                }}
                                onChange={(e) => setCPassword(e.target.value)}
                                value={cPassword}
                                error={!!errors.cPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={handleClickShowCPassword}
                                            onMouseDown={handleMouseDownCPassword}
                                            edge="end"
                                        >
                                            {showCPassword ? <Visibility style={border} /> : <VisibilityOff style={border} />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {errors.cPassword && <Typography color="error">{errors.cPassword}</Typography>}
                        </Stack>
                    </div>
                    <div className='text-center mt-5 text-sm' style={border}>
                        By Signing up, you agree to our <span className='hover:underline' style={{ color: '#2B76BA' }}>Terms of Service</span> and <span className='hover:underline' style={{ color: '#2B76BA' }}>Privacy Policy</span>
                    </div>

                    <div className='mt-5'>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button fullWidth style={{ backgroundColor: "#2B76BA" }} className='mt-5' size="large" type="submit" variant="contained" color="primary" onClick={handleSignUp} disabled={loading}
                                >
                                    <Box color="white" className="font-inter font-semibold tracking-widest" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {loading ? <Loader /> : <p className=' tracking-[4px] w-[70px]'>SignUp</p>}
                                    </Box>
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default SignUp;
