import React, { useState, useEffect } from 'react';
import DataTable from '../components/DataTable';
import '../App.css'
import Header from '../components/Header';
import Table from '../components/Table';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_BASE_URL_PAYMENT } from '../constants';
import { useSelector } from 'react-redux';


const initialData = [
    {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
        col5: '',
    }

];
function Billing() {
    const [data, setData] = useState(initialData);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const user = useSelector(state => state.user);

    const requestHeaders = {
        "Content-Type": "application/json",
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await fetch(
                    `${API_BASE_URL_PAYMENT}/users-subscriptions/${user?.user?._id}`,
                    {
                        method: "GET",
                        headers: requestHeaders,
                    }
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                const mappedData = data.receipts.map(item => {

                    const subscription = data.subscriptions.find(sub => sub.subscriptionId === item.subscriptionId);

                    return {
                        col1: item?.paymentId,
                        col2: item.planName ? item.planName : subscription.planName,
                        col3: new Date(item?.paidAt).toLocaleDateString(),
                        col4: `$${item?.amountPaid}`,
                        col5: new Date(subscription?.endDate).toLocaleDateString(),
                    };
                });
                setLoading(false)

                setData(mappedData)

            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false)
            }
        };

        fetchData();
    }, [user]);







    const columns = React.useMemo(
        () => [
            {
                Header: 'DATE',
                accessor: 'col3',
            },
            {
                Header: 'COST',
                accessor: 'col4',
            },
            {
                Header: 'SUBSCRIPTION',
                accessor: 'col2',
            },

            {
                Header: 'STRIPE PAYMENT ID',
                accessor: 'col1',
            },






        ],
        []
    );
    return (
        <div className='h-[100vh] bg-[#1E1E1E]'>
            <Header Navigate={navigate} />

            <Table tableData={data} loading={loading} columns={columns} title="Billing + Plans" />
        </div>
    );
}

export default Billing;
