import logo from "../../components/assets/public/static/assets/chlk-logo-white.png"
import React, { useEffect, useState } from 'react'
import '../../App.css'
import { useNavigate } from "react-router-dom";


const Wrapper = ({ children }) => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const sentences = [
        'unleash your creativity.'
    ];

    const [displayedSentence, setDisplayedSentence] = useState('');
    const [sentenceComplete, setSentenceComplete] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            const currentSentence = sentences[index];
            const displayedLength = displayedSentence.length;
            if (displayedLength < currentSentence.length) {
                setDisplayedSentence((prev) => prev + currentSentence[displayedLength]);
            } else {
                setSentenceComplete(true);
                clearInterval(timer);
            }
        }, 100); // Adjust typing speed here (milliseconds per character)

        return () => clearInterval(timer);
    }, [index, displayedSentence]);

    useEffect(() => {
        if (sentenceComplete) {
            setTimeout(() => {
                setDisplayedSentence('');
                setSentenceComplete(false);
                setIndex((prevIndex) => (prevIndex + 1) % sentences.length);
            }, 2000); // Adjust delay before changing text here (milliseconds)
        }
    }, [sentenceComplete]);

    return (
        <div className='w-full flex  h-[100vh]'>
            <div className='w-[60%] cursor-pointer relative hidden lg:flex flex-col items-start justify-center px-10 linear-gradient'>
                <div> <div style={{ color: "white", marginLeft: '40px', fontSize: '53px', fontWeight: 'bold', lineHeight: '5px', width: 100, height: 50 }} onClick={() => navigate('/login')}> <img src={logo} alt="" /></div>
                    <p className="custom-font-simple lg:text-[36px] xl:text-[53px] lg:leading-10 xl:leading-[65px]" style={{ color: "white", marginLeft: '40px', marginRight: '40px', marginTop: '65px', fontWeight: '500' }}>
                        A Football Play Design<br /> Software built to <br />
                        <span className="typing-text font-italic custom-font">
                            {displayedSentence ?displayedSentence : 'u'}
                        </span>
                    </p>
                </div>
            </div>
            {children}
        </div>
    )
};
export default Wrapper;

