import { useState, useEffect } from 'react';

// Utility function to get the viewport width and height
const getViewportSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

const useViewport = () => {
  // State to hold the viewport size
  const [viewportSize, setViewportSize] = useState(getViewportSize());

  useEffect(() => {
    // Update the viewport size state on window resize
    const handleResize = () => setViewportSize(getViewportSize());

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Initial update
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return viewportSize;
};

export default useViewport;
