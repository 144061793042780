
import React, { useRef, useEffect, useState } from 'react';
import { Stage, Layer, Image, Rect, Line, Transformer, Group } from 'react-konva';
import useImage from 'use-image';
import Shape from './shapes/Shape';
import TextTag from './shapes/TextTag';
import CustomLine from './shapes/CustomLine';
import LoadedLayer from './shapes/LoadedLayer';
import LineContextMenu from '../components/menus/LineContextMenu';
import { colorButtons } from './Stencil';
import { throttle } from 'lodash';
import useViewport from '../hooks/useViewPort';



function Canvas(props) {
    const {
        currentLayerData,
        setCurrentLayerData,
        startPos,
        endPos,
        lines,
        setLines,
        onLineChange,
        startDrawing,
        draw,
        stopDrawing,
        deleteAllLines,
        colorButtonPressCount,
        strokeTypeButtonPressCount,
        setStrokeTypeButtonPressCount,
        strokeEndButtonPressCount,
        setStrokeEndButtonPressCount, selectedLineColor,
        setSelectedLineColor,
        onLineDelete,
        imageRef,
        stageRef,
        shapes,
        setShapes,
        setSelectedColor,
        selectedShapes,
        setSelectedShapes,
        onSelect, setSelectedTagColor,
        selectedTagColor,
        onShapeChange,
        onShapeDelete,
        textTags,
        selectedTextTags,
        setSelectedTextTags,
        onTextTagChange,
        onTextTagDelete,
        onHideTextTagContextMenu,
        onHideContextMenu,
        selectedColor,
        selectedLineStroke,
        selectedLineEnd,
        setSelectedLineEnd,
        backgroundImage,
        setStageDimensions,
        orientation,
        selectedLineID,
        setSelectedLineID,
        waterMark,
        setWatermark,
        selectedColorButton,
        oldDimensions,
        setSelectedColorButton,
    } = props;
    const trRef = useRef(null);
    const shapeRef = useRef([]);
    const selectionRef = useRef();
    const layerRef = React.useRef();
    const groupRef = React.useRef();

    const [selectionBox, setSelectionBox] = useState(null);
    const [isSelectedBox, setIsSelectedBox] = useState();
    const [transformed, setTransformer] = useState();

    const viewportSize = useViewport();
    const containerRef = useRef(null);
    const [image] = useImage(backgroundImage);
    const [isMouseDownOnAnchor, setIsMouseDownOnAnchor] = useState(false);
    const [selectedShapeID, setSelectedShapeID] = useState([]);
    const [selectedTextTagID, setSelectedTextTagID] = useState('$');
    const [initialMousePosition, setInitialMousePos] = useState();
    const [hasBeenSelected, setHasBeenSelected] = useState(false);
    const deselectShape = () => setSelectedShapeID([]);
    const deselectTextTag = () => setSelectedTextTagID(null);
    const updateSelectedTextTagsColor = (newColor) => {
        selectedTextTags.forEach(tag => {
            onTextTagChange(tag.id, { color: newColor });
        });
    };

    useEffect(() => {
        function fitStageIntoParentContainer() {
            if (containerRef.current && stageRef.current) {
                const { offsetWidth, offsetHeight } = containerRef.current;

                stageRef.current.width(offsetWidth);
                stageRef.current.height(offsetHeight);
                stageRef.current.draw();

                setStageDimensions({ width: offsetWidth, height: offsetHeight });
            }
        }

        function handleResize() {
            fitStageIntoParentContainer();
        }

        fitStageIntoParentContainer();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        updateSelectedTextTagsColor(selectedColor);
    }, [selectedColor]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            const line = lines.find(line => line.id === selectedLineID);

            if (event.key === 'Delete' && line) {
                handleDeleteClick();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedLineID, lines]);


    // useEffect(() => {
    //     function fitStageIntoParentContainer() {
    //         if (containerRef.current && stageRef.current) {
    //             const { offsetWidth, offsetHeight } = containerRef.current;

    //             stageRef.current.width(offsetWidth);
    //             stageRef.current.height(offsetHeight);
    //             stageRef.current.draw();

    //             setStageDimensions({ width: offsetWidth, height: offsetHeight });
    //         }
    //     }

    //     function handleResize() {
    //         fitStageIntoParentContainer();
    //     }

    //     fitStageIntoParentContainer();
    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    useEffect(() => {
        if (selectionBox && selectedShapeID.length < 1) {
            setIsSelectedBox(true)
            setTransformer(true)
        }
    }, [selectionBox]);


    const [showContextMenu, setShowContextMenu] = useState(false);
    const [startPosSelectionBox, setStartSelectionBoxPos] = useState();
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });



    useEffect(() => {
        shapes.map((shape, index) => {
            if (selectedShapeID?.length && selectedShapeID?.includes(shape.id)) {

            }
        })

    }, [selectedShapeID, lines])


    useEffect(() => {

        if (selectedShapeID?.length) {

            const allSelectedRefs = selectedShapeID && selectedShapeID?.map(i => shapeRef.current[i])

            if (allSelectedRefs) {
                trRef?.current?.nodes(allSelectedRefs);
            }
        }


    }, [selectedShapeID, selectedShapes]);

    useEffect(() => {

        if (selectedShapeID.length == 1) {
            setSelectedColor(selectedShapes[0]?.initialColor == 'transparent' ? "#FFFFFF" : selectedShapes[0]?.initialColor)
            const colorIndex = colorButtons.findIndex(i =>
                selectedShapes[0]?.initialColor == 'transparent' ? i == "#FFFFFF" : i == selectedShapes[0]?.initialColor)
            setSelectedColorButton(colorIndex == -1 ? 0 : colorIndex)
        }
        else {

            setSelectedColorButton(-1)
        }
    }, [selectedShapes])


    useEffect(() => {

        onShapeChange(selectedShapeID, { initialColor: selectedColor })

    }, [selectedColor])


    const [oldPlayNamePos, setOldPlayNamePos] = useState({ x: 0, y: 0 });
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        setOldPlayNamePos(playNamePos);
        setImageLoaded(false);
    }, [image]);


    useEffect(() => {
        const timer = setTimeout(() => {
            setImageLoaded(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, [image]);

    const middlePosition = {
        x: imageRef.current?.x() + (imageRef.current?.width() / 2),
        y: imageRef.current?.height() / 2
    };

    const imageSize = {
        width: imageRef.current?.width(),
        height: imageRef.current?.height()
    };

    const playNamePos = imageLoaded
        ? { x: middlePosition?.x - imageSize?.width * 0.47, y: middlePosition?.y - imageSize?.height * 0.475 }
        : oldPlayNamePos;

    const handleDragEndCallback = (selectedShape, selectedIds, newPos, oldPos) => {

        const deltaX = newPos?.x - oldPos.x;
        const deltaY = newPos?.y - oldPos.y;


        const updatedShapes = shapes.map(shape => {
            if (selectedShapeID.length == 1 && selectedShapeID.includes(shape.id)) {
                return {
                    ...shape,
                    initialPosition: {
                        x: newPos?.x,
                        y: newPos?.y
                    }
                }
            } else
                if (selectedShapeID.includes(shape.id)) {
                    const initialX = shape.initialPosition.x;
                    const initialY = shape.initialPosition.y;
                    return {
                        ...shape,
                        initialPosition: {
                            x: initialX + deltaX,
                            y: initialY + deltaY
                        }
                    };
                }
            return shape;
        });


        setShapes(updatedShapes);

    }
    const handleStageMouseUp = (e) => {
        const endPos = e.target?.getStage()?.getPointerPosition();

        stopDrawing();
        setSelectedShapeID([])
        setSelectedShapes([])


        if (!startPosSelectionBox) {

            setSelectionBox(null);
            setIsSelectedBox(null)

            setIsMouseDownOnAnchor(false);
            return;
        }


        const x1 = Math.min(startPosSelectionBox.x, endPos.x);
        const y1 = Math.min(startPosSelectionBox.y, endPos.y);
        const x2 = Math.max(startPosSelectionBox.x, endPos.x);
        const y2 = Math.max(startPosSelectionBox.y, endPos.y);



        const selectedShapesInsideBox = shapes.filter(shape => {




            return shape?.initialPosition?.x >= x1 && shape?.initialPosition?.y >= y1 && shape?.initialPosition?.x <= x2 && shape?.initialPosition?.y <= y2

        }
        );


        if (selectedShapesInsideBox.length > 0 && transformed && selectedShapeID.length < 1) {

            const selectedShapeIDs = selectedShapesInsideBox.map(shape => shape.id);


            setSelectedShapeID(selectedShapeIDs);


            setSelectedShapes(selectedShapesInsideBox);
        }


        setSelectionBox(null);

        setIsMouseDownOnAnchor(false);

    };


    const handleDeleteClick = () => {
        const line = lines.find(line => line.id === selectedLineID);

        if (line) {
            setShowContextMenu(false);
            onLineDelete(selectedLineID);
        }
    };

    const handleHideContextMenu = () => {
        setShowContextMenu(false);
        setSelectedLineID('$');
    }



    const handleStageClick = (e) => {

        setTransformer(true);


        if (e.target === e.target.getStage()) {
            setSelectedShapeID([])
            setSelectedShapes([]);
            setSelectionBox(null)
            deselectShape();
            deselectTextTag();
            setSelectedTextTags([]);
            setSelectedLineID('$');
            setHasBeenSelected(false);
            setIsSelectedBox(true)

        }
    };
    const handleImageClick = () => {
        setSelectionBox(null)
        setSelectedTextTags([]);
        setIsSelectedBox(true)
        deselectTextTag();
    }

    const handleImageDblClick = () => {
        setSelectedShapeID([])
        setSelectedShapes([]);
        setSelectionBox(null)
        deselectShape();
        deselectTextTag();
        setSelectedTextTags([]);
        setSelectedLineID('$');
        setHasBeenSelected(false);
        setIsSelectedBox(true)
    }
    const handleStageMouseDown = (e) => {


        const pointerPos = e?.target?.getStage()?.getPointerPosition()
        if (!selectionBox) {


            setStartSelectionBoxPos(pointerPos);

            setSelectionBox({
                x: pointerPos?.x,
                y: pointerPos?.y,
                width: 0,
                height: 0
            })
        }
    };


    const handleMouseMove = (e) => {
        const pos = e?.target?.getStage()?.getPointerPosition();
        // console.log("Touch Move pos", selectionBox)

        setSelectionBox({
            ...selectionBox,
            width: pos?.x - selectionBox?.x,
            height: pos?.y - selectionBox?.y
        })
        if (lines) {
            draw(pos)
            // console.log("Touch Move")

        }
    }

    const handleStageMouseMove = (e) => {
        const pointerPos = e.target?.getStage()?.getPointerPosition();
        setInitialMousePos(pointerPos);



        if (isMouseDownOnAnchor && e.evt.buttons === 1) {
            const pos = e?.target?.getStage()?.getPointerPosition();

            if (lines)
                draw(pos);
            else {

                const dx = pointerPos.x - startPosSelectionBox.x;
                const dy = pointerPos.y - startPosSelectionBox.y;

                if (transformed && selectedShapeID.length < 1)
                    setSelectionBox({
                        x: selectionBox?.x + dx,
                        y: selectionBox?.y + dy,
                        width: selectionBox?.width,
                        height: selectionBox?.height
                    });
            }



        }
        else
            if (e.evt.buttons === 1) {
                const pointerPos = e?.target?.getStage()?.getPointerPosition()
                if (transformed && selectedShapeID.length < 1)
                    setSelectionBox({
                        ...selectionBox,
                        width: pointerPos?.x - selectionBox?.x,
                        height: pointerPos?.y - selectionBox?.y
                    })
            }

    };


    useEffect(() => {
        console.log("viewportSize", viewportSize)
    }, [viewportSize])

    return (
        <div ref={containerRef} style={{ position: 'relative', width: '100%', height: '90%' }}>
            <Stage
                ref={stageRef}
                width={containerRef.current ? containerRef.current.offsetWidth : 0}
                height={containerRef.current ? containerRef.current.offsetHeight : 0}
                onClick={handleStageClick}
                onMouseDown={handleStageMouseDown}
                onMouseMove={handleStageMouseMove}
                onMouseUp={handleStageMouseUp}
                onTouchStart={(e) => {
                    const pos = e?.target?.getStage()?.getPointerPosition();
                    if (!selectionBox) {
                        setTransformer(true)

                        setStartSelectionBoxPos(pos);

                        setSelectionBox({
                            x: pos?.x,
                            y: pos?.y,
                            width: 0,
                            height: 0
                        })
                    }
                }}
                onTouchMove={handleMouseMove}
                onTouchEnd={handleStageMouseUp}

            >
                <Layer ref={layerRef} >
                    <Image
                        ref={imageRef}
                        x={stageRef.current ? (stageRef.current.width() - (image ? image.width * (containerRef.current ? containerRef.current.offsetHeight / image.height : 0) : 0)) / 2 : 0}
                        y={stageRef.current ? (stageRef.current.height() - (image ? image.height * (containerRef.current ? containerRef.current.offsetHeight / image.height : 0) : 0)) / 2 : 0}
                        image={image}
                        width={image ? image.width * (containerRef.current ? containerRef.current.offsetHeight / image.height : 0) : 0}
                        height={image ? image.height * (containerRef.current ? containerRef.current.offsetHeight / image.height : 0) : 0}

                        // ref={imageRef}
                        // x={stageRef.current ? (stageRef.current.width() - (image ? image.width * (containerRef.current ? containerRef.current.offsetHeight / image.height : 0) : 0)) / 2 : 0}
                        // y={stageRef.current ? (stageRef.current.height() - (image ? image.height * (containerRef.current ? containerRef.current.offsetHeight / image.height : 0) : 0)) / 2 : 0}
                        // image={image}
                        // width={image ? image.width * (containerRef.current ? containerRef.current.offsetHeight / image.height : 0) : 0}
                        // height={image ? image.height * (containerRef.current ? containerRef.current.offsetHeight / image.height : 0) : 0}

                        onClick={handleImageClick}
                        onPointerDblClick={handleImageDblClick}

                    />
                    {waterMark && (
                        <Image
                            image={waterMark}
                            width={waterMark ? waterMark.width / 15 * (containerRef.current ? containerRef.current.offsetHeight / waterMark.height : 0) : 0}
                            height={waterMark ? waterMark.height / 15 * (containerRef.current ? containerRef.current.offsetHeight / waterMark.height : 0) : 0}
                            x={stageRef.current ? (stageRef.current.width() + (waterMark ? waterMark.width / 1.4 * (containerRef.current ? containerRef.current.offsetHeight / waterMark.height : 0) : 0)) / 2 : 0}
                            y={stageRef.current ? (stageRef.current.height() + (waterMark ? waterMark.height / 1.4 * (containerRef.current ? containerRef.current.offsetHeight / waterMark.height : 0) : 0)) / 2 : 0}
                        />
                    )}
                    {lines && lines?.sort((a, b) => (a.id === selectedLineID ? 1 : -1))?.map((line, index) => (
                        <CustomLine
                            key={line.id}
                            id={line.id}
                            line={line}
                            lines={lines}
                            color={line.color}
                            setSelectedLineEnd={setSelectedLineEnd}
                            colorButtonPressCount={colorButtonPressCount}
                            strokeTypeButtonPressCount={strokeTypeButtonPressCount}
                            strokeEndButtonPressCount={strokeEndButtonPressCount}
                            setStrokeTypeButtonPressCount={setStrokeTypeButtonPressCount}
                            setStrokeEndButtonPressCount={setStrokeEndButtonPressCount}
                            selectedColor={selectedLineColor} selectedLineStroke={selectedLineStroke}
                            selectedLineEnd={selectedLineEnd}
                            onLineDelete={onLineDelete}
                            onLineChange={onLineChange}
                            setLines={setLines}
                            selectedLineID={selectedLineID}
                            setSelectedLineID={setSelectedLineID}
                            setIsMouseDownOnAnchor={setIsMouseDownOnAnchor}
                            startDrawing={startDrawing}
                            stageRef={stageRef}
                            imageRef={imageRef}
                            setContextMenuPosition={setContextMenuPosition}
                            setShowContextMenu={setShowContextMenu}
                            showContextMenu={showContextMenu}
                            contextMenuPosition={contextMenuPosition}
                            handleDeleteClick={handleDeleteClick}
                            handleHideContextMenu={handleHideContextMenu}
                        />

                    ))}
                    <Group ref={groupRef}>
                        {shapes && shapes?.map((shape) => {

                            return < React.Fragment key={shape.id} >
                                <Shape
                                    key={el => shapeRef.current[shape.id] = el ?? ''}
                                    shapeRef={el => shapeRef.current[shape.id] = el ?? ''}
                                    selectedLineEnd={selectedLineEnd}
                                    setSelectedLineEnd={setSelectedLineEnd}
                                    hasBeenSelected={hasBeenSelected}
                                    setHasBeenSelected={setHasBeenSelected}
                                    lines={lines}
                                    setLines={setLines}
                                    setIsMouseDownOnAnchor={setIsMouseDownOnAnchor}
                                    startDrawing={startDrawing}
                                    id={shape.id}
                                    shapeType={shape.shapeType}
                                    selectedColor={selectedColor}
                                    shapes={shapes}
                                    setShapes={setShapes}
                                    // initialPosition={(shape?.x && shape?.y) ? { x: shape.x, y: shape.y } : shape.initialPosition}
                                    // initialPosition={oldDimensions.width != imageRef.current.attrs.width && oldDimensions.height != imageRef.current.attrs.height ? {
                                    //     x: (shape.initialPosition.x / oldDimensions.width) * imageRef.current.attrs.width,
                                    //     y: (shape.initialPosition.y / oldDimensions.height) * imageRef.current.attrs.height
                                    // } : shape.initialPosition

                                    // }
                                    // initialPosition={{
                                    //     x: oldDimensions?.width == imageRef?.current?.attrs?.width ? shape.initialPosition.x * (containerRef.current.offsetWidth / stageRef.current.width()) : (shape.initialPosition.x / oldDimensions?.width ?? 1) * imageRef?.current?.attrs?.width ?? 1,
                                    //     y: oldDimensions?.height == imageRef?.current?.attrs?.height ?  shape.initialPosition.y * (containerRef.current.offsetHeight / stageRef.current.height()):(shape.initialPosition.y / oldDimensions?.height ?? 1) * imageRef?.current?.attrs?.height ?? 1
                                    // }}
                                    initialPosition={shape?.initialPosition}
                                    transformed={(props) => setTransformer(props)}
                                    handleDragEndCallback={handleDragEndCallback}
                                    initialColor={shape?.initialColor ? shape?.initialColor : 'white'}
                                    onShapeChange={onShapeChange}
                                    onShapeDelete={onShapeDelete}
                                    onLineDelete={onLineDelete}
                                    onHideContextMenu={onHideContextMenu}
                                    stageRef={stageRef}
                                    imageRef={imageRef}
                                    selectedShapes={selectedShapes}
                                    setSelectedShapes={setSelectedShapes}
                                    selectedShapeID={selectedShapeID}
                                    setSelectedShapeID={setSelectedShapeID}
                                />
                                {/* {changedTranformer(shape)} */}
                                {transformed && selectedShapeID.length > 1 && selectedShapeID.includes(shape.id) && isSelectedBox && (
                                    <Transformer
                                        key={`transformer_${shape.id}`}
                                        ref={trRef}
                                        dragBoundFunc={(pos) => {
                                            const imageX = imageRef.current.x();
                                            const imageY = imageRef.current.y();
                                            const imageWidth = imageRef.current.width();
                                            const imageHeight = imageRef.current.height();

                                            // Calculate boundaries based on image dimensions
                                            let newX = pos.x;
                                            let newY = pos.y;

                                            // Ensure the shape stays within image boundaries
                                            if (newX < imageX) {
                                                newX = imageX;
                                            } else if (newX > imageX + imageWidth) {
                                                newX = imageX + imageWidth - 10;
                                            }

                                            if (newY < imageY) {
                                                newY = imageY;
                                            } else if (newY > imageY + imageHeight) {
                                                newY = imageY + imageHeight - 10;
                                            }

                                            return { x: newX, y: newY };
                                        }}

                                    />
                                )}
                            </React.Fragment>
                        })}
                        {/* Render text tags */}
                    </Group>
                    {textTags && textTags?.map((textTag) => (
                        <TextTag
                            key={textTag.id}
                            id={textTag.id}
                            text={textTag.text}
                            textTags={textTags}
                            initialPosition={textTag?.x || textTag?.y ? { x: textTag?.x, y: textTag?.y } : textTag?.initialPosition}
                            selectedColor={selectedTagColor}
                            color={textTag.color}
                            onTextTagChange={onTextTagChange}
                            onTextTagDelete={onTextTagDelete}
                            onHideTextTagContextMenu={onHideTextTagContextMenu}
                            imageRef={imageRef}
                            setSelectedTextTags={setSelectedTextTags}
                            selectedTextTagID={selectedTextTagID}
                            setSelectedTextTagID={setSelectedTextTagID}
                        />
                    ))}
                    {/* drawing line */}
                    {startPos && endPos && (
                        <Line
                            points={[startPos.x, startPos.y, endPos.x, endPos.y]}
                            stroke="#ACC8DD"
                            strokeWidth={4}
                            tension={0.5}
                            lineCap="round"
                        />
                    )}
                    {showContextMenu &&
                        <LineContextMenu
                            position={contextMenuPosition}
                            onDelete={handleDeleteClick}
                            onMouseLeave={handleHideContextMenu}

                            setSelectedLineEnd={setSelectedLineEnd}
                            selectedLineEnd={selectedLineEnd}
                            setStrokeEndButtonPressCount={setStrokeEndButtonPressCount}
                            handleHideContextMenu={handleHideContextMenu}
                            setStrokeTypeButtonPressCount={setStrokeTypeButtonPressCount}
                        // position={contextMenuPosition}
                        // onDelete={handleDeleteClick}
                        // onMouseLeave={handleHideContextMenu}
                        // setSelectedLineEnd={setSelectedLineEnd}
                        // selectedLineEnd={selectedLineEnd}
                        // setStrokeEndButtonPressCount={setStrokeEndButtonPressCount}

                        // setStrokeTypeButtonPressCount={setStrokeTypeButtonPressCount}
                        />
                    }
                    {
                        selectionBox && <Rect
                            {...selectionBox}
                            fill="#D9F0F970"

                            ref={selectionRef}


                        />
                    }
                </Layer>

            </Stage>

        </div >
    );
}
export default Canvas;
