import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import Wrapper from "../components/Wrapper";
import { Loader } from "../components/Loader";
import { API_BASE_URL_AUTH, API_ROUTES_AUTH } from "../constants";
import { toast } from "react-toastify";
import { signUpdata } from "../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AnimateButton from "../components/AnimateButton";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email } = state ?? {};
  const [cPassword, setCpassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({ newPassword: "", cPassword: "" });
  const [lightMode, setLightMode] = useState(false);
  const { signUpDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const regularExpression =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

  const validatePasswords = () => {
    let valid = true;
    let errors = { newPassword: "", cPassword: "" };
    if (!regularExpression.test(newPassword)) {
      errors.newPassword =
        "Password must be 8-16 characters long and include at least one number and one special character.";
      valid = false;
    }
    if (newPassword !== cPassword) {
      errors.cPassword = "Passwords do not match.";
      valid = false;
    }
    setErrors(errors);
    return valid;
  };

  const handleResetPassword = async () => {
    if (validatePasswords()) {
      setLoader(true);
      //console.log("signUpDetails===>", signUpDetails);

      const requestBody = {
        password: newPassword,
        ...signUpDetails,
      };
      const requestHeaders = {
        "Content-Type": "application/json",
      };

      await fetch(
        API_BASE_URL_AUTH + API_ROUTES_AUTH.UPDATE_PASSWORD,
        {
          method: "POST",
          headers: requestHeaders,
          body: JSON.stringify(requestBody),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setLoader(false);
          //console.log("Response===>", response);
          if (response) {
            toast.success("User Password Updated Successfully!");
            dispatch(signUpdata());
            navigate("/login");
          }
        })
        .catch((error) => {
          //console.log(error.message);
          toast.error(error.message);
          setLoader(false);
        });
    }
  };


  
  const textStyle = { color: lightMode ? "black" : "white" };
  const border = { color: lightMode ? "black" : "white" };
  const inputBorderColor = "black"
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Wrapper>
      <div
        className={`lg:w-[40%] flex items-center ${lightMode ? "bg-white" : "bg-[#1E1E1E]"
          } flex-col w-full px-5 sm:px-20 justify-center`}
      >
        <div className="w-full">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <p className="text-[24px] font-bold" style={border}>
              Reset Password
            </p>
          </Stack>
          <div className="mt-5 hidden">
            <Stack spacing={1}>
              <InputLabel htmlFor="old-Password" style={border}>
                Old Password
              </InputLabel>
              <OutlinedInput
                id="old-password"
                type="oldpass"
                name="OldPass"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: border.color,
                  },
                  "& .MuiInputBase-input": {
                    color: textStyle.color,
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: textStyle.color,
                    opacity: 0.7, // Adjust the opacity for better visibility
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: lightMode ? inputBorderColor : "white",
                  },
                }}
                size="small"
                placeholder="Enter old Password"
                fullWidth
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
              />
            </Stack>
          </div>
          <div className="mt-5">
            <Stack spacing={1}>
              <InputLabel htmlFor="new-pass" style={border}>
                New password
              </InputLabel>
              <OutlinedInput
                id="new-pass"
                type={showPassword ? "text" : "password"}
                name="newPassword"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: border.color,
                  },
                  "& .MuiInputBase-input": {
                    color: textStyle.color,
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: textStyle.color,
                    opacity: 0.7, // Adjust the opacity for better visibility
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: lightMode ? inputBorderColor : "white",
                  },
                }}
                size="small"
                placeholder="Enter new password"
                fullWidth
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                error={!!errors.newPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff style={{ color: '#C8C8C8' }} /> : <Visibility style={{ color: '#C8C8C8' }} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.newPassword && (
                <Typography color="error">{errors.newPassword}</Typography>
              )}
            </Stack>
          </div>
          <div className="mt-5">
            <Stack spacing={1}>
              <InputLabel htmlFor="cnfrm-pass" style={border}>
                Confirm password
              </InputLabel>
              <OutlinedInput
                id="cnfrm-pass"
                type={showConfirmPassword ? "text" : "password"}
                name="cnfrmPassword"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: border.color,
                  },
                  "& .MuiInputBase-input": {
                    color: textStyle.color,
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: textStyle.color,
                    opacity: 0.7, // Adjust the opacity for better visibility
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: lightMode ? inputBorderColor : "white",
                  },
                }}
                placeholder="Enter confirm password"
                fullWidth
                onChange={(e) => setCpassword(e.target.value)}
                value={cPassword}
                error={!!errors.cPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff style={{ color: '#C8C8C8' }} />
                      ) : (
                        <Visibility style={{ color: '#C8C8C8' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.cPassword && (
                <Typography color="error">{errors.cPassword}</Typography>
              )}
            </Stack>
          </div>
          <div className='mt-5'>
            <Grid item xs={12}>
              <AnimateButton>
                <Button fullWidth style={{ backgroundColor: "#2B76BA" }} className='mt-5' size="large" type="submit" variant="contained" color="primary" onClick={handleResetPassword} disabled={loader}
                >
                  <Box color="white" className="font-inter font-semibold tracking-widest" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {loader ? <Loader /> : <p className=' tracking-[4px] w-[70px]'>Submit</p>}
                  </Box>
                </Button>
              </AnimateButton>
            </Grid>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ChangePassword;
