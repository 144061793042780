import React, { useState, useEffect } from 'react';
import DataTable from '../components/DataTable';
import '../App.css'
import Header from '../components/Header';
import Table from '../components/Table';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_BASE_URL_PAYMENT, API_BASE_URL_USER } from '../constants';
import { useSelector } from 'react-redux';
import { LockClosedIcon } from "@radix-ui/react-icons";
import CustomModal from '../components/customModal';
import SearchBar from '../components/SearchBar';



const initialData = [
    {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
        col5: '',
    }
    
];
function Users() {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState(initialData);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const user = useSelector(state => state.user);

    const requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user?.token}`,

    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                if (user?.token) {
                    const response = await fetch(
                        `${API_BASE_URL_USER}/allUsers`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${user?.token}`
                            },
                        }
                    );
                    
                    
                    
                    const data = await response.json();
                    const mappedData = data.userData.map(item => {
                        
                        
                        
                        return {
                            col1: item?.user_details?.firstName ?? '' + item?.user_details?.lastName ?? '', 
                            col2: item?.user_details?.email ?? '', 
                            col3: item?.subscriptions[0]?.planName ?? '',
                            col4: item?.subscriptions[0]?.endDate != "undefined" || item?.subscriptions[0]?.endDate !== undefined ? new Date(item?.subscriptions[0]?.endDate ?? '')?.toLocaleDateString() == "Invalid Date" ? "" : new Date(item?.subscriptions[0]?.endDate ?? '')?.toLocaleDateString() ?? '' : '',
                            pass5: <LockClosedIcon />
                        };
                    });
                    
                    setData(mappedData)
                    setLoading(false)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false)
            }
        };

        fetchData();
    }, [user]); 



    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    const columns = React.useMemo(
        () => [
            {
                Header: 'NAME',
                accessor: 'col1',
                width: '25%'

            },
            {
                Header: 'EMAIL',
                accessor: 'col2',
                width: '70%'

            },
            {
                Header: 'SUBSCRIPTION',
                accessor: 'col3',
                width: '30%'

            },

            {
                Header: 'SUBSCRIPTION EXPIRY',
                accessor: 'col4',
                width: '30%'

            },
            {
                Header: '',
                accessor: 'pass5',
                width: '10%'
            },
        ],
        []
    );
    return (
        <div className='h-[100vh] bg-[#1E1E1E]'>
            <div className='h-[10vh]'>
                <Header Navigate={navigate} />
            </div>

            <div className='max-h-[90vh] overflow-auto '>
                <Table tableData={data} loading={loading} columns={columns} title="Users" handleCellClick={(value) => {
                    setValue(value)
                    setOpen(true)
                }} />
                <CustomModal
                    open={open}
                    handleModalClose={() => { setOpen(false) }}
                    userValue={value}
                />
            </div>
        </div>
    );
}

export default Users;
