import React, { useEffect, useState } from "react";
import { Button, buttonArray, signUpButtonArray } from '../components/button';
import { Modal } from '../components/Modal';
import { useNavigate } from "react-router-dom";
import '../App.css'
const Landingpage = () => {
    const [showModal, setShowModal] = useState(false);
    const [signUp, setSignUp] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [isAnotherWay, setIsAnotherWay] = useState(false);
    
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        firstName: "",
        lastName: "",
        showPassword: false,
    });
    const navigate = useNavigate();

    const handleSignUp = (e) => {
        e.preventDefault();
        const hardcodedEmail = "admin@gmail.com";
        const hardcodedPassword = "password";
        if (
            formData.email === hardcodedEmail &&
            formData.password === hardcodedPassword
        ) {
            navigate("/diagram");
        } else {
            alert("Incorrect email or password");
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const togglePasswordVisibility = () => {
        setFormData({
            ...formData,
            showPassword: !formData.showPassword,
        });
    }

    const handleLogin = (e) => {
        e.preventDefault();
        const hardcodedEmail = "admin@gmail.com";
        const hardcodedPassword = "password";
        if (
            formData.email === hardcodedEmail &&
            formData.password === hardcodedPassword
        ) {
            navigate("/dashboard");
        } else {
            alert("Incorrect email or password");
        }
    };
    //texttttt
    const [text, setText] = useState('');
    useEffect(() => {
        const originalText = '“Unlock Efficiency and Be More Productive”';
        let index = 0;
        const intervalId = setInterval(() => {
            setText(originalText.substring(0, index + 1));
            index++;
            if (index === originalText.length) {
                setTimeout(() => {
                    setText('');
                    index = 0;
                }, 1000);
            }
        }, 100); // Adjust the interval for the desired speed
        return () => clearInterval(intervalId);
    }, []);
    const handleLoginClick = () => {
        setShowModal(true);
    };

    const handleSignupClick = () => {
        setShowModal(true);
        setSignUp(true)
    };

    const handleCloseModal = () => {
        //console.log("Modal")
        setShowModal(false);
        setLoginModal(false)
        setRegisterModal(false)
    };

    return (
        <div className='w-full overflow-auto  noscroll'>

            {/* Header Section start from here  */}
            <div className='bg-[#F1FAFF] noscroll'>
                <div className='w-full  flex justify-between items-center'>
                    <div className='w-[50%] pl-8 pt-7'>
                        <img className='w-[63px] h-[49px]' src="/images/logo.svg" alt="" />
                    </div>
                    <div className='w-[50%] pr-11 pt-10 flex justify-end items-center'>
                        <p className='tracking-widest text-[#2B76BA] mr-6 text-[10px] font-inter  cursor-pointer' style={{ fontWeight: "bold" }} onClick={handleLoginClick}>LOG IN</p>
                        <button onClick={handleSignupClick} style={{ fontWeight: "bold" }} className='bg-[#2B76BA] font-inter custom-letter-spacing  text-[10px] font-bold rounded-[2px] text-white px-6 py-2 text-white'>
                            SIGN UP
                        </button>
                    </div>
                </div>
                {showModal && <Modal handleCloseModal={handleCloseModal} isAnotherWay={isAnotherWay} backPress={() => { setIsAnotherWay(false) }}>
                    {isAnotherWay ? <>
                        {signUpButtonArray.map(v =>
                            <Button key={v.text} bgColor={v.bgColor} text={v.text} img={v.img} onClick={() => {

                                switch (v?.id) {
                                    case "Google":

                                        break;
                                    case "Apple":

                                        break;
                                    case "Email":
                                        handleCloseModal()
                                        if (signUp)
                                            setRegisterModal(true)
                                        else
                                            setLoginModal(true)
                                        break;
                                    case "AnotherWay":
                                        break;
                                    default:
                                        break;
                                }
                            }} />
                        )}
                    </> :
                        <>
                            {buttonArray.map(v =>
                                <Button key={v.text} bgColor={v.bgColor} text={v.text} img={v.img} onClick={() => {
                                    switch (v?.id) {
                                        case "Google":
                                            //console.log("Google")
                                            break;
                                        case "Facebook":
                                            //console.log("facebook")
                                            break;
                                        case "Email":
                                            handleCloseModal()
                                            if (signUp)
                                                setRegisterModal(true)
                                            else
                                                setLoginModal(true)
                                            break;
                                            break;
                                        case "AnotherWay":
                                            setIsAnotherWay(true)
                                            //console.log("AnotherWay");
                                            break;
                                        default:
                                            break;
                                    }
                                }} />
                            )}
                        </>
                    }
                </Modal>}
                {registerModal && <Modal handleCloseModal={handleCloseModal}  >
                    <div class="mt-6 w-[100%]  flex justify-center items-center mx-auto">
                        <form onSubmit={handleSignUp} className="w-[100%]">
                            <div class="grid w-full gap-y-8">
                                <div class="flex-row flex justify-between">
                                    <div class=" w-[45%] ">
                                        <input
                                            placeholder="First name "
                                            type="text"
                                            id="fname"
                                            name="fname"
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                            className={`py-3 px-4 block 3xl:text-xl border-2  bg-gray-100 active:border-none active:outline:none  3xl:py-5 placeholder:3xl:text-lg w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 `}
                                            required
                                        // aria-describedby="email-error"
                                        />

                                    </div>
                                    <div class=" w-[45%]">
                                        <input
                                            placeholder="Last name"
                                            type="text"
                                            id="lname"
                                            name="lname"
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                            className={`py-3 px-4 block border-2  bg-gray-100 3xl:text-xl 3xl:py-5 placeholder:3xl:text-lg w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 `}
                                            required
                                        // aria-describedby="email-error"
                                        />

                                    </div>
                                </div>

                                <div>
                                    <div class="relative ">
                                        <input
                                            placeholder="Email "
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className={`py-3 px-4 block 3xl:text-xl border-2  bg-gray-100 3xl:py-5 placeholder:3xl:text-lg w-full  rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 ${!formData.email
                                                ? "border-2"
                                                : formData.email.includes("@gmail.com")
                                                    ? "border-gray-200  focus:border-gray-400 focus:ring-gray-400"
                                                    : "border-red-500 focus:border-red-500 focus:ring-red-500"
                                                }`}
                                            required
                                            aria-describedby="email-error"
                                        />
                                    </div>
                                    <p
                                        class="hidden text-xs text-red-600 mt-2"
                                        id="email-error"
                                    >
                                        Please include a valid email address so we can get back to
                                        you
                                    </p>
                                </div>

                                <div>


                                    <input
                                        placeholder="Password"
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        className={`py-3 px-4 block 3xl:text-xl border-2  bg-gray-100 3xl:py-5 placeholder:3xl:text-lg w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 `}
                                        required
                                    // aria-describedby="email-error"
                                    />



                                </div>
                                <div>
                                    <input
                                        placeholder="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleInputChange}
                                        className={`py-3 px-4 block 3xl:text-xl 3xl:py-5 border-2  bg-gray-100 placeholder:3xl:text-lg w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 ${formData.password == formData.confirmPassword
                                            ? "border-2"
                                            : "border-red-500 focus:border-red-500 focus:ring-red-500"
                                            }`}
                                        required
                                    // aria-describedby="email-error"
                                    />

                                </div>
                                <div class="relative flex justify-center">
                                    <button
                                        onClick={async (e) => {
                                            e.preventDefault();

                                            // const todoDetails = {
                                            //     name: "Munem MMMM",
                                            //     phone_number: "031322131230",
                                            //     first_name: "Munem",
                                            //     last_name: "MMMM",
                                            //     email: "mnm@mnm.ca"
                                            // };


                                            // await client.graphql({
                                            //     query: mutations.createUser,
                                            //     variables: { input: todoDetails }
                                            // });




                                            // navigate("/verify");

                                            // if (!formData.email || !formData.password) {
                                            //     alert("Please enter both email and password");
                                            //     return;
                                            // }
                                            // handleSignUp(e);
                                        }}
                                        type="submit"
                                        className="w-[100%]  py-3 px-4 3xl:py-5 3xl:text-xl inline-flex justify-center items-center gap-2 rounded-md border border-transparent leading-[21px] font-semibold bg-[#2B76BA] text-[#FFFFFF] hover:bg-[#2B76BA90] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                                    >
                                        Register
                                    </button>
                                </div>
                                <div class="relative flex justify-center" >
                                    <p className="text-sm 3xl:text-lg leading-[20px] text-[12px]  flex justify-center text-gray-300">
                                        Already have an account?
                                    </p>
                                    <span
                                        onClick={() => { 
                                            setRegisterModal(false)
                                        setLoginModal(true)
                                            // navigate('/login')
                                         }}
                                        className="ml-2 font-semibold leading-5  3xl:text-xl text-gray-500"
                                    >
                                        Login
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>}
                {loginModal && <Modal handleCloseModal={handleCloseModal} >
                    <div class="mt-16 w-[100%]  flex justify-center items-center mx-auto">
                        <form onSubmit={handleLogin} className="w-[60%]">
                            <div class="grid w-full gap-y-8">

                                <div>
                                    <div class="relative ">

                                        <input
                                            placeholder="Email "
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className={`py-3 px-4 block 3xl:text-xl border-2  bg-gray-100 3xl:py-5 placeholder:3xl:text-lg w-full  rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 ${!formData.email
                                                ? "border-2"
                                                : formData.email.includes("@gmail.com")
                                                    ? "border-gray-200  focus:border-gray-400 focus:ring-gray-400"
                                                    : "border-red-500 focus:border-red-500 focus:ring-red-500"
                                                }`}
                                            required
                                            aria-describedby="email-error"
                                        />
                                    </div>
                                    <p
                                        class="hidden text-xs text-red-600 mt-2"
                                        id="email-error"
                                    >
                                        Please include a valid email address so we can get back to
                                        you
                                    </p>
                                </div>

                                <div>

                                    <input
                                        placeholder="Password"
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        className={`py-3 px-4 block 3xl:text-xl border-2  bg-gray-100 3xl:py-5 placeholder:3xl:text-lg w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 `}
                                        required
                                    // aria-describedby="email-error"
                                    />



                                </div>
                                <div class="relative flex justify-center">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/diagram')

                                        }}
                                        type="submit"
                                        className="w-[100%]  py-3 px-4 3xl:py-5 3xl:text-xl inline-flex justify-center items-center gap-2 rounded-md border border-transparent leading-[21px] font-semibold bg-[#2B76BA] text-[#FFFFFF] hover:bg-[#2B76BA90] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                                    >
                                        Login
                                    </button>
                                </div>
                                <div class="relative flex justify-center">
                                    <p className="text-sm 3xl:text-lg leading-[20px] text-[12px]  flex justify-center text-gray-300">
                                        Don't have an account?
                                    </p>
                                    <span
                                        onClick={() => {
                                            setLoginModal(false)
                                            setRegisterModal(true)}}
                                        className="ml-2 font-semibold leading-5  3xl:text-xl text-gray-500"
                                    >
                                        Register
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>}
                {/* demo section start from here */}
                <div className='w-full flex justify-center mt-16 items-center'>
                    <p className='text-[#2B76BA] text-[50px] font-bold font-notoserif'>Unleash your creativity</p>
                </div>
                <div className='w-full flex flex-col justify-center items-center'>
                    <p className='text-[#1E1E1E] text-[20px] font-inter -mt-1 font-normal' style={{ fontWeight: "normal" }}>with CHLK’s streamlined Football Play Design Software.</p>
                    <button style={{ fontWeight: "bold" }} className='bg-[#2B76BA] font-inter custom-letter-spacing mt-10   text-[10px] font-bold rounded-[2px] text-white px-3 py-3 text-white'>
                        START diagramMING
                    </button>
                </div>
                <div className='w-full flex justify-center  relative items-center pt-16 pb-10'>
                    <div style={{ fontWeight: "bold" }} className='w-[450px] font-inter z-10 flex justify-center shadow-bottom-right items-center bg-[#E6E6E6] shadow-xl text-[#1E1E1E] h-[270px] custom-letter-spacing font-bold text-[15px]'>
                        DEMO HERE
                    </div>
                    <div>
                        <img className=' h-[150px] w-[200px] absolute top-[30px] right-[12%] lg:top-[30px] lg:right-[25%] z- mx-4' src="/images/vector.svg" alt="" />
                    </div>
                    <div>
                        <img className=' h-[110px] w-[110px] absolute top-[70%] right-[10%] lg:top-[70%] lg:right-[25%] z- mx-4' src="/images/vector (2).svg" alt="" />
                    </div>
                    <div>
                        <img className=' h-[110px] w-[110px] z-20  absolute top-[30px] right-[70%] lg:top-[30px] lg:right-[61%] z- mx-4' src="/images/vector (2).svg" alt="" />
                    </div>
                    <div>
                        <img className=' h-[150px] w-[300px] absolute top-[59%] right-[62%] lg:top-[59%] lg:right-[57%] z- mx-4' src="/images/vector (3).svg" alt="" />
                    </div>
                </div>
                <div className='w-full flex justify-center pb-20 items-center'>
                    <p className='text-[#2B76BA] text-[15px] font-inter font-medium'>Made for the future of coaching.</p>
                </div>


                {/* design and export section  */}
                <div style={{
                    backgroundImage: 'url(/images/backgrounddiv.svg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }} className='bg-[#2B76BA] bg-opactiy-70 pt-[100px] xl:pl-[180px] pl-[50px] pr-[50px] flex justify-between items-center xl:pr-[215px] pb-[115px]'>
                    <div className='xl:w-[20%] lg:w-[25%] w-[35%] text-white font-medium text-[30px] '>
                        <p className='spacing-none leading-normal font-notoserif' style={{ fontWeight: "medium" }}>Easy to design, easy to export.</p>
                        <p className='mt-4 text-white font-normal font-inter text-[15px]' style={{ fontWeight: "normal" }}>Easy to design, easy to export.</p>
                    </div>
                    <div className='lg:w-[70%] w-[60%] mt-5 ' style={{ fontWeight: "normal" }}>
                        <p className='text-white font-normal font-inter  text-[15px]'>At CHLK we don’t think designing a Playbook should be a frustrating process. It should be simple and intuitive. No more learning curve. Take your ideas straight to the field.
                            <p className='mt-2 mb-3 font-inter'>It’s been said, “We shape our tools and then our tools shape us.” Our goal at CHLK is to provide streamlined tools at the intersection of football and technology.</p></p>
                        <button style={{ fontWeight: "bold" }} className='w-[80px] bg-white py-2 rounded-[2px] text-[#2B76BA] custom-letter-spacing font-bold font-inter text-[10px]'>CTA?</button>
                    </div>

                </div>

                {/* black screen start from here  */}
                <div className='bg-[#333333] '>
                    <div className='w-full flex  justify-center items-center'>
                        <p className='font-normal text-[28px] pt-[90px] text-white pb-[60px] font-notoserif '>Premium features to streamline your playbook</p>

                    </div>
                    <div className='flex justify-between xl:mx-[300px] mx-[50px] items-center'>
                        <div className='lg:w-[25%] w-[30%]'>
                            <div style={{ fontWeight: "bold" }} className='w-[200px] text-[#1E1E1E] font-bold font-inter text-[9px] h-[180px] custom-letter-spacing  bg-[#E1F2FB]  flex justify-center items-center'>
                                DEMO GIF
                            </div>
                            <p style={{ fontWeight: "medium" }} className='mt-5 font-medium text-[#1E1E1E] font-inter text-[15px] text-white'>FEATURE 01</p>
                            <p className='mb-5 font-normal w-[90%] text-[#1E1E1E] font-inter text-[9px] text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                        </div>
                        <div className='lg:w-[25%] w-[30%]'>
                            <div style={{ fontWeight: "bold" }} className='w-[200px] text-[#1E1E1E] font-bold font-inter text-[9px] h-[180px] custom-letter-spacing  bg-[#E1F2FB]  flex justify-center items-center'>
                                DEMO GIF
                            </div>
                            <p style={{ fontWeight: "medium" }} className='mt-5 font-medium text-[#1E1E1E] font-inter text-[15px] text-white'>FEATURE 01</p>
                            <p className='mb-5 font-normal w-[90%] text-[#1E1E1E] font-inter text-[9px] text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                        </div>
                        <div className='lg:w-[25%] w-[30%]'>
                            <div style={{ fontWeight: "bold" }} className='w-[200px] text-[#1E1E1E] font-bold font-inter text-[9px] h-[180px] custom-letter-spacing  bg-[#E1F2FB]  flex justify-center items-center'>
                                DEMO GIF
                            </div>
                            <p style={{ fontWeight: "medium" }} className='mt-5 font-medium text-[#1E1E1E] font-inter text-[15px] text-white'>FEATURE 01</p>
                            <p className='mb-5 font-normal w-[90%] text-[#1E1E1E] font-inter text-[9px] text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                        </div>
                    </div>

                    <div className='flex justify-between xl:mx-[300px] mx-[50px] mt-10 pb-16 items-center'>
                        <div className='lg:w-[25%] w-[30%]'>
                            <div style={{ fontWeight: "bold" }} className='w-[200px] text-[#1E1E1E] font-bold font-inter text-[9px] h-[180px] custom-letter-spacing  bg-[#E1F2FB]  flex justify-center items-center'>
                                DEMO GIF
                            </div>
                            <p style={{ fontWeight: "medium" }} className='mt-5 font-medium text-[#1E1E1E] font-inter text-[15px] text-white'>FEATURE 01</p>
                            <p className='mb-5 font-normal w-[90%] text-[#1E1E1E] font-inter text-[9px] text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                        </div>
                        <div className='lg:w-[25%] w-[30%]'>
                            <div style={{ fontWeight: "bold" }} className='w-[200px] text-[#1E1E1E] font-bold font-inter text-[9px] h-[180px] custom-letter-spacing  bg-[#E1F2FB]  flex justify-center items-center'>
                                DEMO GIF
                            </div>
                            <p style={{ fontWeight: "medium" }} className='mt-5 font-medium text-[#1E1E1E] font-inter text-[15px] text-white'>FEATURE 01</p>
                            <p className='mb-5 font-normal w-[90%] text-[#1E1E1E] font-inter text-[9px] text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                        </div>
                        <div className='lg:w-[25%] w-[30%]'>
                            <div style={{ fontWeight: "bold" }} className='w-[200px] text-[#1E1E1E] font-bold font-inter text-[9px] h-[180px] custom-letter-spacing  bg-[#E1F2FB]  flex justify-center items-center'>
                                DEMO GIF
                            </div>
                            <p style={{ fontWeight: "medium" }} className='mt-5 font-medium text-[#1E1E1E] font-inter text-[15px] text-white'>FEATURE 01</p>
                            <p className='mb-5 font-normal w-[90%] text-[#1E1E1E] font-inter text-[9px] text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                        </div>
                    </div>
                </div>
                {/* Choose the plan screen */}
                <div className='bg-[#ffffff] pt-[90px] pb-[90px]'>
                    <div className='flex justify-center items-center'>
                        <p style={{ fontWeight: "medium" }} className='font-notoserif  font-medium  text-[30px] text-[#2B76BA] '>Choose the plan that fits your needs</p>
                    </div>
                    <div className='flex mt-10 justify-between flex-wrap w-full items-center px-[50px] xl:px-[280px]'>
                        {/* card 1  */}
                        <div className='py-10 pr-24 pl-10 bg-[#FCFAF5] xl:w-[32%] lg:w-[30%] md:w-[48%] rounded-[14px]'>
                            <p style={{ fontWeight: "medium" }} className='text-[#2B76BA] text-[24px] custom-letter-spacing font-medium font-inter pb-2'>BASE</p>
                            <p className='text-[#1E1E1E] text-[16px] font-medium font-inter pb-2'>$12.99 / month</p>
                            <p className='text-[#1E1E1E] text-[11px] font-light font-inter pb-3'>or $120 / year</p>

                            <button className='bg-[#2B76BA] text-white text-[12px] custom-letter-spacing  font-bold font-inter py-2 px-2 mb-6'>Start 14-Day Trail</button>
                            <ul className='list-disc'>
                                <p className='text-[#1E1E1E] text-[13px] font-medium font-inter pb-2'>Includes:</p>

                                <li className='text-[#1E1E1E] ml-3 text-[11px]  font-normal font-inter pb-2'>Up to 5 tabs</li>
                                <li className='text-[#1E1E1E]  ml-3 text-[11px]  font-normal font-inter pb-4 border-b-2 border-[#F1B727]'>Secure Plays</li>

                            </ul>
                            <div>
                                <p className='text-[#1E1E1E] mt-6 text-[13px] font-medium font-inter pb-2'> Key Features:</p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Download Capability</span></p>
                                <p className='text-[#1E1E1E] -mt-1 text-[13px] flex items-center font-medium font-inter pb-2'>
                                    <span className='ml-5'>(PNG/JPEG/PDF)</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Unlimited creation</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>

                            </div>

                        </div>
                        {/* card 2  */}
                        <div className='py-9 pr-24 pl-10 bg-[#F6FCFF] xl:w-[32%] lg:w-[30%] md:w-[48%] shadow-bottom-right rounded-[14px]'>
                            <p style={{ fontWeight: "medium" }} className='text-[#2B76BA] text-[24px] custom-letter-spacing font-medium font-inter pb-2'>PREMIUM</p>
                            <p className='text-[#1E1E1E] text-[16px] font-medium font-inter pb-2'>$15.99 / month</p>
                            <p className='text-[#1E1E1E] text-[11px] font-light font-inter pb-3'>or $150 / year</p>

                            <button className='bg-[#2B76BA] text-white text-[12px] custom-letter-spacing  font-bold font-inter py-2 px-2 mb-6'>Start 14-Day Trail</button>
                            <ul className='list-disc'>
                                <p className='text-[#1E1E1E] text-[13px] font-medium font-inter pb-2'>Includes:</p>

                                <li className='text-[#1E1E1E] ml-3 text-[11px]  font-normal font-inter pb-2'>Unlimited tabs</li>
                                <li className='text-[#1E1E1E] ml-3 text-[11px]  font-normal font-inter pb-4 border-b-2 border-[#2B76BA]'>Secure Plays</li>

                            </ul>
                            <div>
                                <p className='text-[#1E1E1E] mt-6 text-[13px] font-medium font-inter pb-2'> Everything in Base, plus:</p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Download Capability</span></p>
                                <p className='text-[#1E1E1E] -mt-1 text-[13px] flex items-center font-medium font-inter pb-2'>
                                    <span className='ml-5'>(PNG/JPEG/PDF)</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Unlimited creation</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>

                            </div>

                        </div>
                        {/* card 3  */}
                        <div className='py-10 pr-24 pl-10 bg-[#FCFAF5] xl:w-[32%] lg:mt-0 md:mt-5 lg:w-[30%] md:w-[48%]  rounded-[14px]'>
                            <p style={{ fontWeight: "medium" }} className='text-[#2B76BA] text-[24px] custom-letter-spacing font-medium font-inter pb-4'>TEAMS</p>
                            <p className='text-[#1E1E1E] text-[16px] font-medium font-inter pb-4'>Let's Talk!</p>
                            {/* <p className='text-[#1E1E1E] text-[11px] font-light font-inter pb-3'>or $120 / year</p> */}

                            <button className='bg-[#2B76BA] text-white text-[12px] custom-letter-spacing  font-bold font-inter py-2 px-2 mb-6'>Contact Us</button>
                            <ul className='list-disc'>
                                <p className='text-[#1E1E1E] text-[13px] font-medium font-inter pb-2'>Includes:</p>

                                <li className='text-[#1E1E1E] text-[11px] ml-3  font-normal font-inter pb-2'>Unlimited tabs</li>
                                <li className='text-[#1E1E1E] ml-3 text-[11px]  font-normal font-inter pb-5
                                 border-b-2 border-[#F1B727]'>Secure Plays</li>

                            </ul>
                            <div>
                                <p className='text-[#1E1E1E] mt-6 text-[13px] font-medium font-inter pb-2'> Everything in Premium, plus:</p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Download Capability</span></p>
                                <p className='text-[#1E1E1E] -mt-1 text-[13px] flex items-center font-medium font-inter pb-2'>
                                    <span className='ml-5'>(PNG/JPEG/PDF)</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Unlimited creation</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>
                                <p className='text-[#1E1E1E]  text-[13px] flex items-center font-medium font-inter pb-2'><svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.5L3.5 8L10.5 1" stroke="#2B76BA" />
                                </svg>
                                    <span className='ml-2'>Something else</span></p>

                            </div>

                        </div>
                    </div>
                </div>



                {/* ready when you are div */}
                <div className='bg-[#E1F2FB]  w-full flex-col pb-[130px]  flex justify-center items-center '>
                    <p style={{ fontWeight: "medium" }} className='font-notoserif font-medium pt-[130px] text-[#2B76BA] text-[30px]'>Ready when you are. </p>
                    <p className='font-inter font-regular  text-[15px]'>Join over XXXX coaches worldwide that are using CHLK to get more done.</p>
                    <button style={{ fontWeight: "bold" }} className='bg-[#2B76BA] mt-8   font-inter custom-letter-spacing  text-[10px] font-bold rounded-[2px] text-white px-4 py-2 text-white'>
                        LET’S TALK BALL
                    </button>

                </div>


                {/* foooter starts from here  */}
                <div className='bg-[#2B76BA] py-[40px] px-[80px] flex justify-between items-center'>
                    <div className='w-[40%]'>
                        <p className='text-[9px] text-white  font-normal font-inter'>info@chlkapp.com
                            ©</p>
                        <p className='text-[9px] text-white  font-normal font-inter'>
                            © 2024 CHLK</p>
                    </div>
                    <div className='w-[10%] flex justify-center items-center'>
                        <img className='w-[63px] h-[49px]' src="/images/group 25.svg" alt="" />

                    </div>
                    <div className='w-[40%] flex justify-end items-center'>
                        <img className='w-[16px] h-[16px]' src="/images/group 26.svg" alt="" />
                        <img className='w-[16px] h-[16px] mx-4' src="/images/group 27.svg" alt="" />
                        <img className='w-[16px] h-[16px]' src="/images/group 28.svg" alt="" />
                        <img className='w-[16px] h-[16px] mx-4' src="/images/group 29.svg" alt="" />


                    </div>
                </div>
            </div>

        </div>
    )
}

export default Landingpage






