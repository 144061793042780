import React from 'react'

export const Button = ({ bgColor = '', text = "", img = '', onClick = () => { } }) => {
    return (
        <div className={`mt-4`} onClick={onClick}>
            <button className={`w-full flex  items-center py-2 rounded-[4px]`} style={{ backgroundColor: bgColor }} >
                {img && (
                    <div className='w-[10%] flex justify-center items-center'>
                        <img className='w-[63px] h-[20px]' src={img} alt="" />
                    </div>
                )}
                {text && (
                    <div className={`w-[${img ? '90%' : '100%'}] flex justify-center items-center`}>
                        <p>{text}</p>
                    </div>
                )}
            </button>
        </div>
    )
}

export const buttonArray = [{ bgColor: "#F2F3F5", text: "Continue with Google", img: "/images/googleIcon.svg",id:'Google' }, { bgColor: "#F2F3F5", text: "Continue with Facebook", img: "/images/facebookIcon.svg", id:'Facebook' }, { bgColor: "#F2F3F5", text: "Continue with email", img: "/images/emailIcon.svg",id:'Email' }, { bgColor: "transparen", text: "Continue another way", img: "",id:'AnotherWay' }]
export const signUpButtonArray = [{ bgColor: "#F2F3F5", text: "Continue with Apple", img: "/images/apple.svg", id:'Apple' },{ bgColor: "#F2F3F5", text: "Continue with Google", img: "/images/googleIcon.svg",id:'Google' },{ bgColor: "#F2F3F5", text: "Continue with Facebook", img: "/images/facebookIcon.svg" ,id:'Facebook'},{ bgColor: "#F2F3F5", text: "Continue with Microsoft", img: "/images/microsoft.svg",id:'Microsoft' }, { bgColor: "#F2F3F5", text: "Continue with Clever", img: "/images/facebookIcon.svg",id:'Clever' }, { bgColor: "#F2F3F5", text: "Continue with email", img: "/images/emailIcon.svg",id:'Email' }, { bgColor: "#F2F3F5", text: "Continue with work email", img: "/images/emailIcon.svg",id:'EmailWork' },{ bgColor: "#F2F3F5", text: "Log in with mobile", img: "/images/mobile.svg" ,id:'MobileNumber'}]
// export const signUpButtonArray = [{ bgColor: "#F2F3F5", text: "Continue with Apple", img: "/images/apple.svg", id:'Apple' },{ bgColor: "#F2F3F5", text: "Continue with Google", img: "/images/googleIcon.svg",id:'Google' },{ bgColor: "#F2F3F5", text: "Continue with Facebook", img: "/images/facebookIcon.svg" ,id:'Facebook'},{ bgColor: "#F2F3F5", text: "Continue with Microsoft", img: "/images/microsoft.svg",id:'Microsoft' }, { bgColor: "#F2F3F5", text: "Continue with Clever", img: "/images/facebookIcon.svg",id:'Clever' }, { bgColor: "#F2F3F5", text: "Continue with email", img: "/images/emailIcon.svg",id:'Email' }, { bgColor: "#F2F3F5", text: "Continue with work email", img: "/images/emailIcon.svg",id:'EmailWork' },{ bgColor: "#F2F3F5", text: "Log in with mobile", img: "/images/mobile.svg" ,id:'MobileNumber'}]
