import React, { useState, useEffect, useRef } from 'react';

import { fabric } from "fabric";
import CustomLineFabric from '../components/shapes/CustomLineFabric';

const FabricExample = () => {
    const fabricRef = React.useRef(null);
    const canvasRef = React.useRef(null);

    React.useEffect(() => {
        const initFabric = () => {
            fabricRef.current = new fabric.Canvas(canvasRef.current);
        };

        const addRectangle = () => {
            const rect = new fabric.Rect({
                top: 50,
                left: 50,
                width: 50,
                height: 50,
                fill: "red"
            });

            fabricRef.current.add(rect);
        };

        const disposeFabric = () => {
            fabricRef.current.dispose();
        };

        initFabric();
        addRectangle();

        return () => {
            disposeFabric();
        };
    }, []);

    return <canvas ref={canvasRef} style={{ flex: 1 }} width={window.innerWidth} height={window.innerHeight} >
        <CustomLineFabric />
    </canvas>;
};


export default FabricExample;