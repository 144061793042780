import React, { useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import emailjs from '@emailjs/browser';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { API_BASE_URL, API_ROUTES_GENERAL } from '../../constants';
import button from "../../components/assets/public/Images/button.svg";


export default function FeedBackForm(props) {

  const {
    open,
    handleFeedbackFormClose,
    handleFeedbackFormSubmit,
    openSnackbar,
    setOpenSnackbar,
    snackbarMessage,
    setSnackbarMessage,
    snackbarSeverity,
    setSnackbarSeverity,
  } = props;

  const form = useRef();

  const sendFeedback = async (e) => {
    e.preventDefault();

    const email = form.current['from_email'].value;
    const message = form.current['message'].value;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email || !message) {
      setSnackbarMessage('Both fields are required!');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    if (!emailRegex.test(email)) {
      setSnackbarMessage('Invalid email format!');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    if (sessionStorage.getItem('feedbackSent')) {
      setSnackbarMessage('You have already sent feedback in this session!');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const requestBody = {
      email: email,
      feedback: message
    };
    //console.log(requestBody)
    const requestHeaders = {
      "Content-Type": "application/json",
    };
    await fetch(
      API_BASE_URL + API_ROUTES_GENERAL.SEND_FEEDBACK,
      {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setSnackbarMessage('Feedback sent successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        sessionStorage.setItem('feedbackSent', 'true');
        handleFeedbackFormClose();
        // toast.success(response?.message);
      })
      .catch((error) => {
        //console.log(error)
        setSnackbarMessage('Failed to send feedback!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });


  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };


  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleFeedbackFormClose} >
        <div className='bg-[#333333]'>
          <DialogTitle style={{ color: "white" }}> Feedback</DialogTitle>
          <DialogContent >
            <DialogContentText style={{ color: "white" }}>
              To send us feedback, please enter your email address and message below. We will get back to you as soon as possible.
            </DialogContentText>
            <form ref={form} onSubmit={sendFeedback}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email address"
                type="email"
                fullWidth
                variant="standard"
                name="from_email"
                InputProps={{
                  style: { color: 'white' } // Change input text color to white
                }}
                InputLabelProps={{
                  style: { color: 'white' } // Change placeholder text color to white
                }}
              />
              <TextField
                autoFocus
                margin="dense"
                id="feedback"
                label="Your feedback"
                type="feedback"
                fullWidth
                variant="standard"
                name="message"
                InputProps={{
                  style: { color: 'white' } // Change input text color to white
                }}
                InputLabelProps={{
                  style: { color: 'white' } // Change placeholder text color to white
                }}
              />
              <DialogActions>
                <Button onClick={handleFeedbackFormClose}>Cancel</Button>
                <Button type="submit" label="Submit" style={{ backgroundColor: "#1565C0", borderRadius: "4px", color: "white", fontWeight: "medium", padding: "5px 15px" }}>
                  Send

                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>

      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
}