import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
// import Highlighter from './third-party/Highlighter';

const headerSX = {
  p: 2.5,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

const MainCard = forwardRef((props, ref) => {
  const {
    border = true,
    boxShadow,
    children,
    subheader,
    content = true,
    contentSX = {},
    darkTitle,
    divider = true,
    elevation,
    secondary,
    shadow,
    sx = {},
    title,
    codeHighlight = false,
    codeString,
    modal = false,
    ...others
  } = props;

  const theme = useTheme();
  const finalBoxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

  return (
    <Card
      elevation={elevation || 0}
      ref={ref}
      {...others}
      sx={{
        position: 'relative',
        border: border ? '1px outset' : 'none',
        borderRadius: 2,
        borderColor:  theme.palette.divider ,
        boxShadow: finalBoxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
        ':hover': {
          boxShadow: finalBoxShadow ? shadow || theme.customShadows.z1 : 'inherit'
        },
        ...(codeHighlight && {
          '& pre': {
            m: 0,
            // p: '12px 12px 0px 12px !important',
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.75rem'
          }
        }),
        ...(modal && {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 'calc( 100% - 50px)', sm: 'auto' },
          '& .MuiCardContent-root': {
            overflowY: 'auto',
            minHeight: 'auto',
            maxHeight: 'calc(100vh - 200px)'
          }
        }),
        ...sx
      }}
    >
      {!darkTitle && title && (
        <CardHeader
          sx={headerSX}
          titleTypographyProps={{ variant: 'subtitle1' }}
          title={title}
          action={secondary}
          subheader={subheader}
        />
      )}
      {darkTitle && title && <CardHeader sx={headerSX} title={<Typography variant="h4">{title}</Typography>} action={secondary} />}
      {title && divider && <Divider />}
      {content && <CardContent sx={contentSX}>{children}</CardContent>}
      {!content && children}
      {codeString && (
        <>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {/* <Highlighter codeString={codeString} codeHighlight={codeHighlight} /> */}
        </>
      )}
    </Card>
  );
});

export default MainCard;
