import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import Wrapper from '../components/Wrapper';
import { Loader } from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL_AUTH, API_ROUTES_AUTH } from '../constants';
import { signUpdata, userToken } from '../redux/slices/userSlice';
import { toast } from 'react-toastify';
import { userUpdate } from '../redux/slices/userSlice';
import OtpInput from "react-otp-input";
import AnimateButton from '../components/AnimateButton';
import { Box, Button, Grid } from '@mui/material';
const OtpPage = () => {


    const [otp, setOtp] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [lightMode, setLightMode] = useState(false);
    const { signUpDetails } = useSelector(state => state.user) ?? {}
    const dispatch = useDispatch()
    const handleChange = (value) => {










    };
    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
        if (event.key == 'Enter')
            handleSubmit()
    };
    const handleSubmit = async () => {
        const enteredOtp = otp
        setLoading(true)

        if (enteredOtp) {
            if (enteredOtp?.length == 6) {
                if (signUpDetails?.firstName) {

                    const requestBody = {
                        otp: enteredOtp,
                        ...signUpDetails
                    };
                    const requestHeaders = {
                        "Content-Type": "application/json",
                    };
                    await fetch(API_BASE_URL_AUTH + API_ROUTES_AUTH.REGISTER, {
                        method: "POST",
                        headers: requestHeaders,
                        body: JSON.stringify(requestBody)
                    })
                        .then(response => { return response.json() })
                        .then(response => {
                            setLoading(false)

                            if (!response?.message?.includes('Invalid OTP') || response?.message != "Invalid OTP") {

                                toast.success("User Registered Successfully!")

                                dispatch(signUpdata())
                                dispatch(userUpdate({ user: response?.userData, loggedIn: true }));
                                dispatch(userToken({ token: response?.token }));

                                navigate('/plans');

                            } else {
                                setLoading(false)

                                toast.error("Invalid Otp.")

                            }
                        }).catch((error) => {

                            toast.error(error.message)
                            setLoading(false)
                        })
                } else {

                    const requestHeaders = {
                        "Content-Type": "application/json",
                    };
                    await fetch(API_BASE_URL_AUTH + API_ROUTES_AUTH.VERIFY_OTP, {
                        method: "POST",
                        headers: requestHeaders,
                        body: JSON.stringify({ otp: enteredOtp })
                    }).then(response => { return response.json() })
                        .then(response => {
                            setLoading(false)

                            if (!response?.message?.includes('Invalid OTP') || response?.message != "Invalid OTP") {
                                navigate('/update-password')
                            } else {
                                setLoading(false)

                                toast.error("Invalid Otp.")
                            }
                        }).catch((error) => {

                            toast.error(error.message)
                            setLoading(false)
                        })
                }
                setLoading(false)
            }

        }
        else {
            setLoading(false)

            toast.error("Please Enter Otp")
        }
    };
    const handleResend = async () => {
        const requestHeaders = {
            "Content-Type": "application/json",
        };
        await fetch(API_BASE_URL_AUTH + API_ROUTES_AUTH.RESEND_OTP, {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify({ email: signUpDetails?.email })
        }).then(response => { return response.json() })
            .then(response => {
                setLoading(false)

                if (response?.success) {
                    toast.success("Otp Resent.")
                }
            }).catch((error) => {

                toast.error(error.message)
                setLoading(false)
            })
        setLoading(false)

    };
    const textStyle = { color: lightMode ? 'black' : 'white' };
    const border = { color: lightMode ? 'black' : 'white' };
    return (
        <Wrapper>
            <div className={`lg:w-[40%] flex items-center  flex-col w-full px-5 sm:px-32 ${lightMode ? "bg-white" : "bg-[#1E1E1E]"} justify-center`}>
                <div className='w-[100%] flex flex-col items-center justify-center'>
                    <h1 className="text-2xl text-black font-bold mb-4" style={border}>Enter OTP</h1>
                    <h4 className="text-black font-300 mb-4" style={border}>OTP has been sent to your Email.</h4>
                    <div className="flex justify-center mb-8">
                        <OtpInput
                            containerStyle="grid grid-cols-6 gap-2 sm:gap-3"
                            inputStyle="h-9 !w-9 sm:h-[50px] sm:!w-[50px] color-[#FFFFFF] text-white text-center bg-transparent rounded-md border border-[#C8C8C8] mt-3"
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType={'number'}
                            renderInput={(props) => <input {...props} />}
                        // renderSeparator={<span>-</span>}
                        />
                    </div>
                    <div className='mt-5 w-full'>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button fullWidth style={{ backgroundColor: "#2B76BA" }} className='mt-5 w-full' size="large" type="submit" variant="contained" color="primary" onClick={handleSubmit} disabled={loading}
                                >
                                    <Box color="white" className="font-inter font-semibold tracking-widest w-[100%] " style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {loading ? <Loader /> : <p className=' tracking-[4px] w-[70px]'>Submit</p>}
                                    </Box>
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </div>
                    <div style={border} className="flex  mb-8 cursor-pointer hover:underline mt-4" onClick={handleResend}>
                        Resend Verificaiton Code
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
export default OtpPage;