
import { Children } from 'react';
import { Button, buttonArray, signUpButtonArray } from '../components/button';
export const Modal = ({ handleCloseModal, isAnotherWay, children,backPress }) => {
    return (
        <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50' /*onClick={handleCloseModal} **/>
            <div className='w-full flex justify-center items-start'>
                <div className='lg:w-[55%] w-[60%] rounded-[10px] flex bg-white md:h-[90vh] lg:h-[81vh]'>
                    <div className='lg:w-[50%]   w-full rounded-bl-[10px] md:rounded-br-[10px] md:rounded-tr-[10px] lg:rounded-br-[0px] lg:rounded-tr-[0px] rounded-tl-[10px] pt-[50px]  px-[54px] bg-white' >
                        <button disabled={!isAnotherWay} onClick={backPress} className='text-[#2B76BA] font-semibold flex items-center text-[24px] font-notoserif z-10'>
                            {isAnotherWay ? <svg className='mr-4' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 1L1 6L6 11" stroke="#2B76BA" />
                            </svg> : <></>}
                            {isAnotherWay ? 'Back to CHLK' : 'Log in or sign up'}
                        </button>
                       { !isAnotherWay && <p className='text-[#000000] font-medium text-[10px] font-inter'>
                            Use your email or another service to continue with CHLK!
                        </p>}
                        {children}
                    </div>
                    <div
                        className='w-[50%] lg:block hidden rounded-br-[10px] rounded-tr-[10px]'
                        style={{
                            backgroundImage: 'url(/images/background.svg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    ></div>
                </div>
                <button
                    onClick={handleCloseModal}
                    className='w-[20px] ml-2 h-[20px] flex justify-center items-center rounded-full bg-[#2B76BA]'
                >
                    <img className='w-[15px] h-[15px]' src='/images/cross.svg' alt='' />
                </button>
            </div>
        </div>
    );
};
