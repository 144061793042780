// slices/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    loggedIn: false,
    signUpDetails: {},
    token: "",
  },
  reducers: {
    userUpdate: (state, actions) => {
      state.user = actions.payload.user;
      state.loggedIn = actions.payload.loggedIn;
    },
    signUpdata: (state, actions) => {
      state.signUpDetails = actions.payload;
    },
    userToken: (state, actions) => {
      state.token = actions.payload.token;
    },
  },
});

export const { userUpdate, signUpdata, userToken } = userSlice.actions;

export default userSlice.reducer;
