import React, { useState, Fragment, useEffect } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Box, CircularProgress, Typography } from '@mui/material';
import MainCard from '../components/MainCard';
import { API_BASE_URL_PAYMENT, API_ROUTES_PAYMENT } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { userUpdate } from '../redux/slices/userSlice';
import '../App.css'
import { Loader } from '../components/Loader';

// Sample data to simulate the plans
const samplePlans = [
    {
        "_id": "665ea4e4b1c077acf2fd5b87",
        "title": "Monthly Subscription",
        "price": 12.99,
        "per": "Per Month",
        "description": "Get Base level access to CHLK's App which includes full stencil functionality and ability to store up to four plays in the playbook.",
        "contentLines": [
            {
                "content": "test content 1",
                "status": true,
                "_id": "665479ab1f0bb2561514fc93"
            },
            {
                "content": "test content 2",
                "status": true,
                "_id": "665479ab1f0bb2561514fc94"
            },
            {
                "content": "test content 3",
                "status": false,
                "_id": "665479ab1f0bb2561514fc95"
            }
        ],
        "stripePriceId": "price_1PJ7iaDtAjmN4bYiaQMVMRNJ",
        "createdAt": "2024-05-27T12:16:43.779Z",
        "updatedAt": "2024-05-27T12:16:43.779Z",
        "__v": 0,
        "discount": false
    }
    , {
        "_id": "665ea553b1c077acf2fd5b88",
        "title": "Annual Subscription",
        "price": 120.00,
        "per": "Per Year",
        "discount": "save over 23%",
        "description": "Get Base level access to CHLK's App which includes full stencil functionality and ability to store up to four plays in the playbook.",
        "contentLines": [
            {
                "content": "test content 1",
                "status": true,
                "_id": "665438bff7c844bd979e42af"
            },
            {
                "content": "test content 2",
                "status": true,
                "_id": "665438bff7c844bd979e42b0"
            },
            {
                "content": "test content 3",
                "status": true,
                "_id": "665438bff7c844bd979e42b1"
            }
        ],
        "stripePriceId": "price_1PJ7ktDtAjmN4bYiXmSkOJgt",
        "createdAt": "2024-05-27T07:39:43.135Z",
        "updatedAt": "2024-05-27T07:39:43.135Z",
        "__v": 0
    }

];

const initialData = [
    { id: 1, name: 'John Doe', age: 28, email: 'john@example.com', phone: '123-456-7890' },
    { id: 2, name: 'Jane Smith', age: 34, email: 'jane@example.com', phone: '234-567-8901' },
    { id: 3, name: 'Alice Johnson', age: 23, email: 'alice@example.com', phone: '345-678-9012' },
    // Add more data as needed
];

function Plans() {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState(initialData);
    const navigate = useNavigate()
    const [currentPlan, setCurrentPlan] = useState();
    const user = useSelector(state => state.user);
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const dispatch = useDispatch();

    const filteredData = data.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    //console.log("User==>", user?.user)

    const theme = useTheme();
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedPlanIndex, setSelectedPlanIndex] = useState(-1);
    const [loading, setLoading] = useState(false);

    const priceListDisable = {
        opacity: 0.4,
        '& >div> svg': {
            fill: theme.palette.secondary.light,
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const requestHeaders = {
                    "Content-Type": "application/json",
                };

                const response = await fetch(
                    `${API_BASE_URL_PAYMENT}${API_ROUTES_PAYMENT.GET_USER_SUBSCRIPTION}/${user?.user?._id}`,
                    {
                        method: "GET",
                        headers: requestHeaders,
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const responseData = await response.json();
                setLoading(false);
                //console.log("Response===>", responseData);
                //console.log("User===>", user)
                dispatch(userUpdate({ user: { ...user?.user, planName: responseData.planName, subscriptionStatus: responseData.subscriptionStatus }, loggedIn: true }));
                setCurrentPlan(responseData)
            } catch (error) {
                console.error(error);
                toast.error(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <div className='w-full flex justify-center flex-col  items-center h-[100vh] bg-[#1E1E1E]'>
            <div className='h-[10vh] w-full overflow-auto bg-[#1E1E1E]'>
                <Header Navigate={navigate} disableCursor={user?.user?.planName === null} />

            </div>
            <div className='w-full flex justify-center flex-col items-center h-[90vh]  overflow-auto bg-[#1E1E1E] noscroll' >
            {loading ?
                        <Loader />
                        :
                <div className=' w-[70%] flex  justify-between mt-10 items-center'>

                   
                       { samplePlans.map((plan, index) => (
                            <div className='ml-10  w-[100%] ' key={index}>
                                <MainCard sx={{ pt: 1.75, backgroundColor: '#333333', border: '1px solid #555555', color: '#FFFFFF', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)' }}>
                                    <div className=' w-[100%]  '>

                                        <Grid item xs={12} >
                                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                                <div className="custom-font-simple " style={{ display: "flex", justifyContent: "space-between", borderBottom: "2px solid #1E1E1E", paddingBottom: "20px", color: "white", fontWeight: '500', fontSize: '42px', width: '100%', }}>
                                                    <p className='w-[60%] '>{plan.title}</p>
                                                    <p style={{ fontWeight: "normal" }} className='text-[24px] relative w-[40%]  leading-16  leading-1  flex flex-col   items-end font-inter'>  ${plan.price}
                                                        <p className='text-[11px] font-medium leading-2'>{plan.per}</p>
                                                        <div className='   w-[100%]  flex items-end flex-col '><p className='text-[14px]   pt-14  custom-font font-medium leading-2'>{plan.discount}

                                                        </p>
                                                        </div>
                                                    </p>

                                                </div>

                                            </Stack>
                                        </Grid>

                                        <div style={{ marginTop: "20px", marginBottom: "10px", padding: "10px 0px" }} >
                                            {currentPlan?.planName === plan?.title && currentPlan?.subscriptionStatus === 'active' ? (
                                                <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={async () => {
                                                        setSelectedPlanIndex(index);
                                                        setLoading(true);
                                                        const requestBody = {
                                                            userId: user?.user?._id
                                                        };
                                                        const requestHeaders = {
                                                            "Content-Type": "application/json",
                                                        };
                                                        await fetch(
                                                            API_BASE_URL_PAYMENT + API_ROUTES_PAYMENT.CANCEL,
                                                            {
                                                                method: "POST",
                                                                headers: requestHeaders,
                                                                body: JSON.stringify(requestBody),
                                                            }
                                                        )
                                                            .then((response) => response.json())
                                                            .then((response) => {
                                                                setLoading(false);
                                                                if (response?.cancelSubscription) {
                                                                    window.open(response?.cancelSubscription, '_blank');
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                 toast.error(error.message);
                                                                setLoading(false);
                                                            });
                                                    }}
                                                    className='font-inter tracking-[4px]'
                                                    style={{
                                                        backgroundColor: 'transparent', 
                                                        border: '1px solid #2B76BA',
                                                        color: 'white',
                                                        fontWeight: 'semibold',
                                                        latterSpacing: "4px",
                                                        padding: '10px 0px',

                                                    }}
                                                >
                                                    {loading && index === selectedPlanIndex ? (
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: theme.palette.mode === 'dark' && selectedPlan === plan.title ? 'white' : 'white',
                                                            }}
                                                        >
                                                            <CircularProgress size={24} color="inherit" />
                                                        </Box>
                                                    ) : (
                                                        'Cancel'
                                                    )}
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant={currentPlan?.planName === plan.title ? 'contained' : 'outlined'}
                                                    fullWidth
                                                    onClick={async () => {
                                                        setSelectedPlanIndex(index);
                                                        setLoading(true);
                                                        switch (plan.title) {
                                                            case "Annual Subscription": {
                                                                const requestBody = {
                                                                    planId: plan._id,
                                                                    userId: user?.user?._id
                                                                };
                                                                const requestHeaders = {
                                                                    "Content-Type": "application/json",
                                                                };
                                                                await fetch(
                                                                    API_BASE_URL_PAYMENT + API_ROUTES_PAYMENT.SUBSCRIBE,
                                                                    {
                                                                        method: "POST",
                                                                        headers: requestHeaders,
                                                                        body: JSON.stringify(requestBody),
                                                                    }
                                                                )
                                                                    .then((response) => response.json())
                                                                    .then((response) => {
                                                                        setLoading(false);
                                                                        if (response?.url) {
                                                                            window.open(response?.url, '_blank');
                                                                        }
                                                                    })
                                                                    .catch((error) => {
                                                                         toast.error(error.message);
                                                                        setLoading(false);
                                                                    });
                                                                break;
                                                            }
                                                            case "Monthly Subscription": {
                                                                const requestBody = {
                                                                    planId: plan._id, userId: user?.user?._id
                                                                };
                                                                const requestHeaders = {
                                                                    "Content-Type": "application/json",
                                                                };
                                                                await fetch(
                                                                    API_BASE_URL_PAYMENT + API_ROUTES_PAYMENT.SUBSCRIBE,
                                                                    {
                                                                        method: "POST",
                                                                        headers: requestHeaders,
                                                                        body: JSON.stringify(requestBody),
                                                                    }
                                                                )
                                                                    .then((response) => response.json())
                                                                    .then((response) => {
                                                                        setLoading(false);
                                                                        if (response?.url) {
                                                                            window.open(response?.url, '_blank');

                                                                        }
                                                                    })
                                                                    .catch((error) => {
                                                                         toast.error(error.message);
                                                                        setLoading(false);
                                                                    });
                                                                break;
                                                            }
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                    style={{
                                                        backgroundColor: selectedPlan === plan.title ? '' : '#1565C0',
                                                        fontWeight: 'bolder',
                                                        padding: '10px 0px',
                                                        color: theme.palette.mode === 'dark' && selectedPlan === plan.title ? 'white' : 'white',
                                                    }}
                                                >
                                                    {loading && index === selectedPlanIndex ? (
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: theme.palette.mode === 'dark' && selectedPlan === plan.title ? 'white' : 'white',
                                                            }}
                                                        >
                                                            <CircularProgress size={24} color="inherit" />
                                                        </Box>
                                                    ) : (
                                                        'Subscribe'
                                                    )}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </MainCard>
                            </div>
                        ))}
                </div>
}

                <div className=' flex justify-center items-center '>
                    <p className='font-inter  font-medium text-[18px] leading-5 tracking-[4px]  text-white mt-16'>ALL SUBSCRIPTIONS INCLUDE:</p>
                </div>
                <div className='w-full xxl:px-[300px] xl:px-[300px] lg:px-[200px] md:px-[100px]  flex md:flex-row flex-col  px-[50px ] items-start justify-between'>
                    <div className='md:w-[45%] mt-10'>
                        <p className='flex items-center mb-6'> <svg width="17" height="12" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5L3.5 8L10.5 1" stroke="#F1B727" />
                        </svg> <span className='ml-3 leading-5 text-[16px] text-white' style={{ fontWeight: "400" }} >Bezier Curve Line Functionality for Maximum Route Accuracy</span>
                        </p>
                        <p className='flex items-center mb-6'> <svg width="17" height="12" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5L3.5 8L10.5 1" stroke="#F1B727" />
                        </svg> <span className='ml-3 leading-5 text-[16px] text-white' style={{ fontWeight: "400", width: '100%' }} >“Player Halo” ensures you don’t need to leave the canvas to draw lines or add line endings</span>
                        </p>
                        <p className='flex items-center mb-6'> <svg width="17" height="12" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5L3.5 8L10.5 1" stroke="#F1B727" />
                        </svg> <span className='ml-3 leading-5 text-[16px] text-white' style={{ fontWeight: "400" }} >Field Dimensions for all levels (HS, College, and NFL)</span>
                        </p>
                        <p className='flex items-center mb-6'> <svg width="17" height="12" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5L3.5 8L10.5 1" stroke="#F1B727" />
                        </svg> <span className='ml-3 leading-5 text-[16px] text-white' style={{ fontWeight: "400" }} >Quickly Toggle from Midfield to Red Zone</span>
                        </p>
                    </div>

                    <div className='md:w-[45%] mt-10'>
                        <p className='flex items-center mb-6'> <svg width="17" height="12" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5L3.5 8L10.5 1" stroke="#F1B727" />
                        </svg> <span className='ml-3 leading-5 text-[16px] text-white' style={{ fontWeight: "400" }} >Master the Pass Game with QB Progressions & Text Tags</span>
                        </p>
                        <p className='flex items-center mb-6'> <svg width="17" height="12" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5L3.5 8L10.5 1" stroke="#F1B727" />
                        </svg> <span className='ml-3 leading-5 text-[16px] text-white' style={{ fontWeight: "400" }} >Click-through Offensive Line Shading</span>
                        </p>
                        <p className='flex items-center mb-6'> <svg width="17" height="12" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5L3.5 8L10.5 1" stroke="#F1B727" />
                        </svg> <span className='ml-3 leading-5 text-[16px] text-white' style={{ fontWeight: "400" }} >Access to Custom CHLK Playbook Templates </span>
                        </p>
                        <p className='flex items-center mb-6'> <svg width="17" height="12" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5L3.5 8L10.5 1" stroke="#F1B727" />
                        </svg> <span className='ml-3 leading-5 text-[16px] text-white' style={{ fontWeight: "400" }} >
                                Lightning Fast Download capability (PNG/JPG)</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Plans;
