
import React, { useState } from 'react';
import { Rect, Group } from 'react-konva';
import ContextMenu from '../../menus/ContextMenu';

const CenterSquare = (props) => {
    const {
        id,
        startDrawing,
        setIsMouseDownOnAnchor,
        shapeRef,
        position,
        initialColor,
        showContextMenu,
        contextMenuPosition,
        handleRightClick,
        handleDeleteClick,
        handleDragStart,
        handleDragMove,
        handleDragEnd,
        handleHideContextMenu,
        rectSize,
        shiftPressed,
        dragBoundFunc,
        selectedShapeID,
        setSelectedShapeID,
        setHasBeenSelected,
        hasBeenSelected,
        transformed

    } = props;


    const isSelected = (selectedShapeID && selectedShapeID?.find?.(i => i === id)) ?? false;
    const [isDrawing, setIsDrawing] = useState(false);

    const strokeOptions = { color: 'black', strokeWidth: 2 };
    const centerLineWidth = 3.5;
    const haloOffset = 13;

    const states = [
        { leftState: 0, rightState: 100, colorOne: initialColor, colorTwo: "black" },
        { leftState: 0, rightState: rectSize.width / 2, colorOne: "black", colorTwo: initialColor },
        { leftState: 0, rightState: rectSize.width / 2, colorOne: initialColor, colorTwo: "black" },
        { leftState: -1, rightState: 0, colorOne: initialColor, colorTwo: initialColor }
    ];

    const [stateIndex, setStateIndex] = useState(0);
    const [state, setState] = useState(states[stateIndex]);

    const handleCenterClick = () => {


        if (shiftPressed) {

            setSelectedShapeID([...selectedShapeID, id]);
            transformed(true)

        } else {

            setSelectedShapeID([id]);
            transformed(false)

        }



        if (hasBeenSelected && selectedShapeID?.includes(id)) {
            const newIndex = (stateIndex + 1) % states.length;
            setStateIndex(newIndex);
            setState(states[newIndex]);
        }

        if (!hasBeenSelected) {
            setHasBeenSelected(true);
        }
    };


    const handleTouchStart = (e) => {
        const touchPos = e.target.getStage().getPointerPosition();
        startDrawing(touchPos, id, null, position);
        setIsMouseDownOnAnchor(true);
        setIsDrawing(true);
        e.cancelBubble = true;
    };

    const handleTouchMove = (e) => {
        if (!isDrawing) return;
        const touchPos = e.target.getStage().getPointerPosition();
        handleDragMove(e, touchPos);
    };

    const handleTouchEnd = (e) => {
        setIsDrawing(false);
        handleDragEnd(e);
    };
    return (
        <>
            <Group
                ref={shapeRef}
                x={position.x}
                y={position.y}
                onContextMenu={handleRightClick}
                draggable={isSelected ? true : false}
                onDragStart={handleDragStart}
                onDragMove={handleDragMove}
                onDragEnd={handleDragEnd}
                dragBoundFunc={dragBoundFunc}
                onTouchStart={handleDragStart}
            >
                {isSelected && (
                    <Rect
                        width={rectSize.width + haloOffset}
                        height={rectSize.height + haloOffset}

                        fill='white'
                        strokeWidth={2}
                        cornerRadius={2}
                        shadowBlur={15}
                        shadowColor='#184267'
                        offsetX={(rectSize.width + haloOffset) / 2}
                        offsetY={(rectSize.height + haloOffset) / 2}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        onMouseDown={(e) => {
                            const startPos = e.target.getStage().getPointerPosition();

                            startDrawing(startPos, id, null, position);
                            setIsMouseDownOnAnchor(true);
                            e.cancelBubble = true;
                        }}
                        onMouseEnter={(e) => {
                            const container = e.target.getStage().container();


                            container.style.cursor = 'crosshair';
                        }}
                        onMouseLeave={(e) => {
                            const container = e.target.getStage().container();
                            container.style.cursor = 'default';
                        }}
                    />
                )}
                <Rect
                    width={rectSize.width}
                    height={rectSize.height}
                    stroke={strokeOptions.color}
                    strokeWidth={strokeOptions.strokeWidth}
                    cornerRadius={2}
                    offsetX={rectSize.width / 2}
                    offsetY={rectSize.height / 2}
                    onClick={handleCenterClick}
                    onTap={handleCenterClick}
                    fillLinearGradientStartPoint={{ x: state?.leftState, y: 0 }}
                    fillLinearGradientEndPoint={{ x: state?.rightState, y: 0 }}
                    fillLinearGradientColorStops={[1, state?.colorOne, 1, state?.colorTwo]}
                />
                {stateIndex === 3 && (
                    <Rect
                        x={-1.6}
                        y={-rectSize.height / 2}
                        onClick={handleCenterClick}
                        width={centerLineWidth}
                        height={rectSize.height}
                        fill="black"
                    />
                )}
            </Group>
            {showContextMenu && <ContextMenu position={contextMenuPosition} onDelete={handleDeleteClick} onMouseLeave={handleHideContextMenu} />}
        </>
    );
}

export default CenterSquare;